import ACTIONS from "../actiontypes";

export default function(state={}, action)
{
	switch(action.type)
	{
		case ACTIONS.SETTINGS.SAVED:
			return {
				...state,
				inProgress:false,
				errors:action.error?action.payload.errors:null
			};

		case ACTIONS.ASYNC.START:
			return {
				...state,
				inProgress:true
			};

		default:
			return state;
	
	}
}