import { v4 as uuidv4 } from 'uuid';

import ACTIONS from "../actiontypes";


export default function (state = [], action)
{
	switch (action.type)
	{
		case ACTIONS.NOTIFICATION.CREATE:
			const rg = [...state,
				{...action.notification, 
					timestamp:Date.now(),
					uuid: uuidv4(),
				}];
			return rg;

		case ACTIONS.NOTIFICATION.MODIFY:
			return state.map(notification=>{

				if (notification.uuid === action.notification.uuid)
					notification = {...notification, ...action.notification};
				return notification;
			})

		default:
			return state;

	}

}