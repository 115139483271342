import React from 'react';
import agent from "../../agent";
import { connect } from 'react-redux';

import { TeamEditorEmbeddable} from "../Team/Editor";

import Modal from "../Modal";

export default function (props)
{
	return (
		<Modal
			{...props}
			title="Meeting"
		>
			<TeamEditorEmbeddable
				title="Meeting"
				inTransientMode={true}
				{...props}
			/>
		</Modal>

	)
}

