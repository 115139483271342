import React from 'react';
import { connect } from 'react-redux';
import InputGroup from 'react-bootstrap/InputGroup';

import { Typeahead, Highlighter } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import ACTIONS from "../../actiontypes";

import ToggleButton from "../ToggleButton";

const mapStateToProps = state => ({
	currentUser: state.common.currentUser,
	personalDirectory: state.common.personalDirectory,
	teams: state.teams,
	userList: state.userman.userlist
});

const mapDispatchToProps = dispatch => ({
	onGetUserGoal: payload =>
		dispatch({ type: ACTIONS.USERGOAL.LOAD, payload }),
	onCreateUserGoal: description =>
		dispatch({ type: ACTIONS.GOAL.LOAD_FORM, description }),
});



class TeamSelect extends React.PureComponent
{
	constructor()
	{
		super();

		this.state = {
			name: '',
			description: '',
			currentGoal: null,
		};


		this.filterBy = (team, state) =>
		{
			if (state.selected.length)
			{
				return true;
			}
			return team.name.toLowerCase().indexOf(state.text.toLowerCase()) > -1;
		}

		this.onToggleStatus = (currentgoal) =>
		{
			//agent.UserGoals.set(currentgoal.goal, currentgoal.status === "up" ? "down" : "up");
		}
	}

	componentDidMount()
	{
		const { teams, onSelect } = this.props;

		this.onChange = (select) =>
		{
			if (select && select[0])
			{
				const team = select[0];
				console.debug("onChange", team);

				if (teams.details[team.id])
				{
					if (onSelect)
						onSelect(team);
				}
			}
		}
	}

	render()
	{
		const { currentUser, teams, userList } = this.props;

		if (!(teams && teams.details && userList && currentUser))
			return null;

		const renderMenuItemChildren = (team, { text }, index) => (
			<React.Fragment>
				<Highlighter search={text}>
					{team.name}
				</Highlighter>

				{/* 				{team.description &&
					<small>
						{team.description}
					</small>
				}
 */}			</React.Fragment>
		);

		return (
			<InputGroup>
				<Typeahead
					renderMenuItemChildren={renderMenuItemChildren}
					filterBy={this.filterBy}
					id="Team-Select-personal"
					options={Object.entries(teams.details).map(([id, team]) => team).filter(team => !team.transient)}
					labelKey="name"
					//onInputChange={(text, ev) => { console.debug(text, ev) }}
					onChange={this.onChange}
					//onBlur={(ev)=>{console.debug(ev)}}
					placeholder="Select a team...">

					{({ isMenuShown, toggleMenu }) => (
						<ToggleButton isOpen={isMenuShown} onClick={e => toggleMenu()} />
					)}
				</Typeahead>
			</InputGroup>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamSelect);
