import React from 'react';


export function MapToIconCharacter(status)
{
	const rg={
		"up":"👍",
		"down": "👎"
	};

	return (status && rg[status.toLowerCase()]) || "👊";
}

export function ScoreToStatus(score)
{
	if (score>0)
		return "up";
	else if (score<0)
		return "down";

}

export default function(props)
{

	return(
		<React.Fragment>
			{MapToIconCharacter(props.goal.status)}
		</React.Fragment>
	)
}