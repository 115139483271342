import React from 'react';
import { connect } from 'react-redux';

import TeamImage from "./Image";

import Dskly from "../Dskly";
import { ChatUrl, Addressee } from "../../Utils/Communicator";

const mapStateToProps = state => ({
	activeConnections: state.connections,
});

function TeamCard (props)
{
	const { members, globalDirectory, team, className, currentUser, onAction, actionSymbol, activeConnections } = props;

	const callhash = team.id;
	const address = Addressee.MakeTo(Addressee.Type.group, callhash);
	const fActiveCall = !!Object.values(activeConnections).find(connection => connection.address === address && connection.channel==="videocall");
	const fActiveChat = !!Object.values(activeConnections).find(connection => connection.address === address && connection.channel === "chat");


	const currentMembers = members
		.map(({ user: id }) => id)
		.map(id =>
		{
			const u = globalDirectory.find(u => id === u.id);
			return u;
		})
		.filter(e => !!e)
		;

	const mailinglist = currentMembers
		.filter(u => u.id !== currentUser.id && u.email)
		.map(u => u.email)
		;
	
	return (
		<Dskly
			className={className}
			actionSymbol={actionSymbol}
			onAction={() => onAction && onAction(team)}
//			callLink={TeamCallUrl(team.id)}
//			callNumber={team.id}
			cdr={{
				from:currentUser.id,
				to: Addressee.MakeTo(Addressee.Type.group, team.id),
				//uuid: uuidv4(),
				address,
			}}
			fActiveCall={fActiveCall }
			fActiveChat={fActiveChat }
			fShakeCard={team.transient}

			emailLink={team.transient?null:`mailto:${mailinglist.join(",")}`}
			profileImage={
				<TeamImage
					image={team.image}
					name={team.name}
					inMeeting={team.transient}
				/>
			}
			profileLink={`/team/${team.id}`}
			chatLink={ChatUrl(address)}

			headerContent={
				<React.Fragment>
					{team.name}
				</React.Fragment>

			}
			content={
				<div>
					{currentMembers.length} member{currentMembers.length>1?"s":""}
					<div>
						&nbsp;
					</div>
				</div>
			}
		>
		</Dskly>
	);

}

export default connect(mapStateToProps, () => ({}))(TeamCard);

