import agent, { FileStoreAttachmentPath } from "../../agent";
import React from "react";

export default function (props)
{
	const { fileinfo, showsize } = props;
	if (fileinfo && fileinfo.path)
	{
		const path = FileStoreAttachmentPath(fileinfo.path);

		return fileinfo.mimetype.startsWith("image/") ? (
			<img
				src={path}
			>
			</img>
		) : (<div>
			<a
				href={path}
					download={fileinfo.name}
			>📎 {fileinfo.name} 
			{showsize&& (<small>&nbsp;({fileinfo.size} B)</small>)}
			</a>
		</div>)
	}
	return null;
}