import ACTIONS from "../actiontypes";
import MAGICUSERS from "../MagicUsers/Konst";

function absorbGroups(state, rg)
{
	if (rg)
	{


		const allGroups = rg.reduce((rg, g) =>
		{
			rg[g.id] = g;
			return rg;
		}, {});

		return {
			...state,
			inProgress: false,
			details: {
				...state.details,
				...allGroups
			}
		}
	}
	return state;
}

function absorbUsers(state, allUsers)
{
	if (allUsers && allUsers.length > 0)
	{
		const groupid = allUsers[0].group;

		return {
			...state,
			inProgress: false,
			memberList: {
				...state.memberList,
				[groupid]: allUsers
			}
		};

	}

	return state;
}

export default function (state = { memberList: {}, list: [], details: {} }, action)
{
	switch (action.type)
	{

		case ACTIONS.TEAM.CREATE:
			return {
				...state,
				inProgress: false,
				errors: action.error ? action.payload.errors : null
			};

		case ACTIONS.ASYNC.START:
			return {
				...state,
				inProgress: true
			};

		case ACTIONS.TEAM.ADD_MEMBER:
			return {
				...state,
				inProgress: false,
			}


		case ACTIONS.TEAM.LOAD_PUBLIC:
			if (action.error)
				return {
					...state,
					inProgress: false
				};
			else
			{
				return absorbGroups(state, action.payload.allGroups);
			}
			break;

		case ACTIONS.TEAM.LOAD_LIST:
			if (action.error)
				return {
					...state,
					inProgress: false
				};
			else
			{
				return {
					...state,
					inProgress: false,
					list: [...new Set([...state.list, ...action.payload.allGroups])]
				}
			}
			break;

		case ACTIONS.TEAM.LOAD_DETAILS:
			if (action.error)
				return {
					...state,
					inProgress: false
				};
			else
			{
				const group = action.payload.group;

				if (!group.defunct)
					return {
						...state,
						inProgress: false,
						details: {
							...state.details,
							[group.id]: group
						}
					}
			}
			break;

		case MAGICUSERS.EVENT.GROUPIE:
			if (action.error)
				return state;
			else
			{
				const { groupuser, cmd } = action.payload;
				const rgMembers = state.memberList[groupuser.group] || [];
				if (cmd === "update" || cmd === "create") 
				{
					return {
						...state,
						memberList: {
							...state.memberList,
							[groupuser.group]: rgMembers.concat(groupuser)
						}
					}
				}
				else if (cmd === "delete")
				{
					return {
						...state,
						memberList: {
							...state.memberList,
							[groupuser.group]: rgMembers.filter(g => g.id !== groupuser.id)
						}
					}
				}
			}
			break;

		case ACTIONS.TEAM.LOAD_USERS:
			if (action.error)
				return {
					...state,
					inProgress: false
				};
			else
			{
				return absorbUsers(state, action.payload.allUsers);
			}

		case ACTIONS.TEAM.LOAD_SELF_USERS:
		case ACTIONS.TEAM.LOAD_PUBLIC_USERS:
			if (action.error)
				return {
					...state,
					inProgress: false
				};
			else
			{
				state = absorbGroups(state, action.payload.allGroups);;

				let rgUsers = action.payload.allUsers;
				while (rgUsers && rgUsers.length > 0)
				{
					const first = rgUsers[0];

					const rgU = rgUsers.filter(u => u.group === first.group);
					state = absorbUsers(state, rgU);

					rgUsers = rgUsers.filter(u => u.group !== first.group);
				}
				return state;
			}

			break;

		case MAGICUSERS.EVENT.GROUP:
			if (action.error)
				return state;
			else
			{
				const { group, cmd } = action.payload;
				if (cmd === "update" || cmd === "create") 
				{
					return {
						...state,
						details: {
							...state.details,
							[group.id]: group
						}
					}
				}
				else if (cmd === "delete")
				{
					const newstate = { ...state };
					delete newstate.details[group.id];
					return newstate;
				}

			}
			break;

		default:
			return state;

	}

	return state;
}