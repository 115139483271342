
import ACTIONS from "../actiontypes";
import MAGICUSERS from "../MagicUsers/Konst";


export default function (state = {}, action)
{
	switch (action.type)
	{
		case ACTIONS.COMMUNICATOR.JOIN:
			{
				const { connection } = action;
				if (connection)
				{
					return {
						...state,
						[connection.id]: connection,
					};
				}

			}
			break;


		case ACTIONS.COMMUNICATOR.LEAVE:
			{
				const { connection } = action;
				if (connection)
				{

					const newstate = { ...state };
					delete newstate[connection.id];
					return newstate;
				}

			}
			break;


		case ACTIONS.CONNECTIONS.LIST:
			if (!action.error)
			{

				return action.payload.connections.reduce((state, connection)=>{
					if (connection && connection.id)
						state[connection.id]=connection;
					return state;
				}, state);
			}
			break;

		default:

			return state;
	}

	return state;
}