import ACTIONS from "../actiontypes";

export default function (state = {}, action)
{
	switch (action.type)
	{
		case ACTIONS.GOAL.LOAD:
			return {
				...state,
				...(action.payload.goal),
			};

		case ACTIONS.GOAL.LOAD_FORM:
			return {
				...state,
				editor: action.description,
			};

		case ACTIONS.GOAL.UNLOAD_FORM:
			return {
				...state,
				editor: null,
			};

		default:
			return state;
	}
}