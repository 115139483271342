
import { applyMiddleware, createStore, combineReducers } from 'redux';
import { promiseMiddleware, localStorageMiddleware } from "./middleware";
import magicusersMiddleware from "./MagicUsers/middleware";

import auth from "./reducers/auth";
import common from "./reducers/common";
import home from "./reducers/home";
import settings from "./reducers/settings";
import article from "./reducers/article";
import articleList from "./reducers/articleList";
import profile from "./reducers/profile";
import editor from "./reducers/editor";
import userman from "./reducers/userman";
import mood from "./reducers/mood";
import goal from "./reducers/goal";
import teams from "./reducers/teams";
import goalmatrix from "./reducers/goalmatrix";
import notifications from "./reducers/notifications";
import connections from "./reducers/connections";

import { createBrowserHistory } from 'history';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { createLogger } from 'redux-logger';

export const history = createBrowserHistory();

const reducer = combineReducers({
	auth,
	common,
	home,
	settings,
	article,
	articleList,
	profile,
	editor,
	userman,
	mood,
	goal,
	teams,
	goalmatrix,
	notifications,
	connections,
	router: connectRouter(history)
});

const middleware = [routerMiddleware(history), promiseMiddleware, localStorageMiddleware, magicusersMiddleware];
if(process.env.NODE_ENV !== 'production')
	middleware.push(createLogger());

export const store = createStore(reducer, composeWithDevTools(applyMiddleware(...middleware)));

