import React from 'react';
import Button from 'react-bootstrap/Button';

import agent from "../../agent";


import { MapToIconCharacter } from "./icon";

function onToggleStatus(goalid, status)
{
	agent.GoalMatrix.set(goalid, status === "up" ? "down" : "up");

}

export default function (props)
{
	const { goal, status, fToggleButton } = props;

	if (goal)
	{
		if (fToggleButton)
			return (
				<Button
					onClick={e => onToggleStatus(goal.id, status)}
				>
					{MapToIconCharacter(status)}
				</Button>
			);
		else
			return (
				<React.Fragment>
					{MapToIconCharacter(status)}
				</React.Fragment>
			);
	}

	return null;
}
