import React from 'react';

export default class ListErrors extends React.Component
{
	render()
	{
		const errors = this.props.errors;

		if (errors)
			return (
				<ul className="error-messages">
					{
						Object.keys(errors).map(key =>
						{
							return (
								<li key={key}>
									{key} {JSON.stringify(errors[key])}
								</li>
							);
						})
					}
				</ul>
			);
		else
			return null;
	}
}