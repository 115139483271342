import ACTIONS from "../actiontypes";


export default function (state = {}, action)
{
	switch (action.type)
	{
		case ACTIONS.PAGE.PROFILE_LOADED:
		case ACTIONS.PAGE.FAVORITES_LOADED:
			if (action.error)
				return {};
			else
				return { ...action.payload[0].profile };
			break;

		case ACTIONS.PAGE.PROFILE_UNLOADED:
		case ACTIONS.PAGE.FAVORITES_UNLOADED:
			return {};
		break;

		case ACTIONS.USER.FOLLOW:
		case ACTIONS.USER.UNFOLLOW:
			return { ...action.payload.profile };

		default:
			return state;

	}

	return state;
}