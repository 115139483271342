import React from 'react';
import { Link } from 'react-router-dom';

function Inner(props)
{
	const { profileStyle, children } = props;

	return (
		<div className="profile-image rounded-lg square-box" style={profileStyle}>
			<div className="square-content">
				{children}
			</div>
		</div>
	);
}

export default function (props)
{
	const { profileLink, className } = props;
	return (
		<div className={`${className} profile-image-container`}>
			{profileLink ?
				(<Link to={profileLink}>
					<Inner {...props} />
				</Link>
				) : (<Inner {...props} />)
			}
		</div>
	);
}