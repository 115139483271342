import ACTIONS from "../actiontypes";


export default function(state={}, action)
{
	switch(action.type)
	{
		case ACTIONS.PAGE.ARTICLE_LOADED:
			return{
				...state,
				article:action.payload[0].article,
				comments:action.payload[1].comments
			};

		case ACTIONS.PAGE.ARTICLE_UNLOADED:
			return {};

		case ACTIONS.COMMENT.ADD:
			return{
				...state,
				commentErrors:action.error?action.payload.errors:null,
				comments:action.error? null: (state.comments||[]).concat([action.payload.comment])
			};

		case ACTIONS.COMMENT.DELETE:
			const commentId = action.commentId;
			return {
				...state,
				comments:state.comments.filter(comment=> comment.id !== commentId)
			};

		default:
			return state;
		
	}

}