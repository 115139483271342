import ACTIONS from "../actiontypes";

export default function (state = {}, action)
{
	switch (action.type)
	{
		case ACTIONS.APP.LOGIN:
		case ACTIONS.APP.REGISTER:
			return {
				...state,
				inProgress: false,
				errors: action.error ? action.payload.errors : null
			};

		case ACTIONS.PAGE.LOGIN_UNLOADED:
		case ACTIONS.PAGE.REGISTER_UNLOADED:
			return {};

		case ACTIONS.ASYNC.START:
			if (action.subtype === ACTIONS.APP.LOGIN || action.subtype === ACTIONS.APP.REGISTER)
				return { ...state, inProgress: true };
			break;

		case ACTIONS.AUTH.UPDATE_FIELD:
			return { ...state, [action.key]: action.value };

		default:
			return state;
	}
	return state;
}