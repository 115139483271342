import makeEnum from "./Utils/makeEnum";

const APP = makeEnum([
	"LOAD",
	"REDIRECT",
	"LOGOUT",
	"LOGIN",
	"REGISTER",
	"CHANGE_TAB",
]);


const COMMENT=makeEnum([
	"ADD",
	"DELETE"
]);


const USER = makeEnum([
	"FOLLOW",
	"UNFOLLOW"
]);

const PAGE = makeEnum([
	"SET",
	"LOGIN_LOADED",
	"LOGIN_UNLOADED",
	"REGISTER_LOADED",
	"REGISTER_UNLOADED",
	"HOME_LOADED",
	"HOME_UNLOADED",
	"ARTICLE_LOADED",
	"ARTICLE_UNLOADED",
	"PROFILE_LOADED",
	"PROFILE_UNLOADED",
	"FAVORITES_LOADED",
	"FAVORITES_UNLOADED",
	"SETTINGS_LOADED",
	"SETTINGS_UNLOADED",
]);

const EDITOR = makeEnum([
	"PAGE_LOADED",
	"PAGE_UNLOADED",
	"UPDATE_FIELD"
]);


const SETTINGS = makeEnum([
	"SAVED",
	"STOP"
]);

const TEAM = makeEnum([
	"CREATE",
	"DELETE",
	"LOAD_LIST",
	"LOAD_PUBLIC",
	"LOAD_USERS",
	"LOAD_DETAILS",
	"ADD_MEMBER",
	"DEL_MEMBER",
	"LOAD_PUBLIC_USERS",
	"LOAD_SELF_USERS",
]);

const ASYNC = makeEnum([
	"START",
	"STOP"
]);

const ARTICLE = makeEnum([
	"SUBMITTED",
	"STOP",
	"FAVORITED",
	"UNFAVORITED",
	"DELETE"
]);

const AUTH = makeEnum([
	"UPDATE_FIELD",
	"STOP"
]);

const USERMAN = makeEnum([
	"LOADED",
	"LOADING"
]);

const MOOD = makeEnum([
	"LOAD_LIST",
	"LOAD",
	"LOAD_CURRENT_STATUS"
]);

const GOAL = makeEnum([
	"LOAD_LIST",
	"LOAD",
	"CREATE",
	"LOAD_FORM",
	"UNLOAD_FORM",
]);

const USERGOAL = makeEnum([
	"LOAD",
]);

const GOALMATRIX = makeEnum([
	"LOAD_USER_STATUSES",
]);

const AVAILABILITY = makeEnum([
	"LOAD",
	"LOAD_INDEX"
]);

const PERSONAL_DIRECTORY = makeEnum([
	"LOAD",
	"LOAD_GROUPS",
]);

const COMMUNICATOR= makeEnum([
//	"ANSWER",
//	"MAKECALL",
	"LEAVE",
	"JOIN",
]);

const NOTIFICATION = makeEnum([
	"CREATE",
	"MODIFY",
]);

const CONNECTIONS = makeEnum([
	"LIST",

]);

export default{
	APP,
	AUTH,
	COMMENT,
	USER,
	PAGE,
	ASYNC,
	SETTINGS,
	EDITOR,
	ARTICLE,
	USERMAN,
	MOOD,
	GOAL,
	USERGOAL,
	AVAILABILITY,
	PERSONAL_DIRECTORY,
	TEAM,
	GOALMATRIX,
	COMMUNICATOR,
	NOTIFICATION,
	CONNECTIONS,
};