import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Nav } from 'react-bootstrap';


import UserInfo from "./User";

/*
function LoggedOutView(props)
{
	if (!props.currentUser)
	{
		return (
			<ul className="nav navbar-nav pull-xs-right">
				<li className="nav-item">
					<Link to="/" className="nav-link">
						Home
					</Link>
				</li>

				<li className="nav-item">
					<Link to="login" className="nav-link">
						Sign In
					</Link>
				</li>
				<li className="nav-item">
					<Link to="register" className="nav-link">
						Register
					</Link>
				</li>

			</ul>
		);
	}
	return null;
}

*/
function DialogLink(props)
{
	if (props.onAction)
		return (
			<Nav.Item>
				<Link
					to={`#${props.label}`}
					className="nav-link"
					onClick={(event) =>
					{
						event.preventDefault();
						props.onAction();
					}
					}
				>
					{props.children}
				</Link>
			</Nav.Item>
		);

	return null;
}

const mapStateToProps = state => ({
	appName: state.common.appName,
	currentUser: state.common.currentUser,
});

function LoggedInView(props)
{
	if (props.currentUser)
	{

		return (
			<div className="justify-content-center">
				<UserInfo
					className="w-100"
					currentUser={props.currentUser}
				/>
				<Nav fill className="flex-column mb-auto">

					<Nav.Item>
						<Link to="/" className="nav-link">
							<i className="ion-ios-home-outline" />
						</Link>
					</Nav.Item>

					<Nav.Item>
						<Link to="/settings" className="nav-link">
							<i className="ion-gear-a" />
						</Link>
					</Nav.Item>

					<Nav.Item >
						<Link to="/teamedit" className="nav-link">
							<i className="ion-android-contacts" />
						</Link>
					</Nav.Item>

					<Nav.Item >
						<Link to="/goal" className="nav-link">
							<i className="ion-flag" />
						</Link>
					</Nav.Item>

					<Nav.Item >
						<Nav.Link
							href="/watercooler"
							rel="opener"
							target="_blank">
							<i className="ion-coffee" />
						</Nav.Link>
					</Nav.Item>

					<DialogLink
						label="Meeting"
						onAction={props.onCreateMeeting}>
						<i className="ion-calendar" />
					</DialogLink>

					<Nav.Item >
						<Link to="/logout" className="nav-link">
							<i className="ion-log-out" />
						</Link>
					</Nav.Item>
				</Nav>

			</div>


		);
	}

	return null;
}

class MainHeader extends React.Component
{
	render()
	{
		//	console.debug(this.props);
		return (
			<div className="dskly-sidebar bg-primary ">
				<nav className="flex-column justify-content-center nav-fill navbar-nav">
					<div className="nav-item">
						<Link to="/" className="h1 nav-link pb-5">
							{this.props.appName}
						</Link>

					</div>

					{/* 
					<li className="nav-item">
						<LoggedOutView currentUser={this.props.currentUser} />
					</li>

 */}

					<LoggedInView
						currentUser={this.props.currentUser}
						onCreateMeeting={this.props.onCreateMeeting}
					/>
				</nav>
			</div>
		);
	}
};


export default connect(mapStateToProps, () => ({}))(MainHeader);