import React from 'react';
import agent from "../../agent";
import { connect } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';

//import DropdownButton from 'react-bootstrap/DropdownButton';

import ACTIONS from "../../actiontypes";

const mapStateToProps = state => ({
	availabilityList: state.common.availabilityList,
	currentUser: state.common.currentUser,
});

const mapDispatchToProps = dispatch => ({
	onGetUserAvailability: payload =>
		dispatch({ type: ACTIONS.AVAILABILITY.LOAD, payload }),

});


const rgAvailMap=[
	["busy", "Busy", "red"],
	["meeting", "In Meeting", "pink"],
	["available", "Available", "green"],
	["dnd", "Do Not Disturb", "red"],
//	["offline", "Offline", "grey"],
];

let availMap={};
rgAvailMap.forEach(a=>{
	availMap[a[0]] = {
		status:a[0],
		label:a[1],
		color:a[2]
	};
});

function availentry(availability)
{
	return availability && availability.status && availMap[availability.status];
}

export function AvailabilityColor(availability)
{
	const a = availentry(availability);

	if (a)
		return a.color;
	
	return "white";
}

export function AvailabilityLabel(availability)
{
	const a = availentry(availability);

	if (a)
		return a.label;

	return "";
}

export class AvailabilityIndicator extends React.PureComponent
{
	render()
	{
		const { availability } = this.props;
		const a = availentry(availability);

		if (a)
			return (
				<span className="dskly-availabilityindicator">
					{a.label}
					</span>
			);
			
		return (
			<span className="dskly-availabilityindicator">
&nbsp;
			</span>
		);
	}
}

class AvailabilityOption extends React.PureComponent
{
	render()
	{

		const availability = this.props.availability;
		const setavailability = (ev) =>
		{
			agent.Availability.set(availability.status);
		}
		return (
			<Dropdown.Item
				value={availability.status}
				onClick={setavailability}
			>
				{availability.label}
			</Dropdown.Item>
		)
	}
}

class AvailabilitySelect extends React.PureComponent
{

	componentDidMount()
	{
		this.props.onGetUserAvailability(agent.Availability.get(this.props.currentUser.id));
	}

	render()
	{
		const { currentUser, availabilityList} = this.props;
		const currentavailability = availabilityList[currentUser.id] ||{};

		//console.debug("availablilty", currentavailability);
		return (
			<Dropdown id="availabilityselector_" >
				<Dropdown.Toggle variant="success" id="dropdown-availabilityselector_" className="mb-2">
					<AvailabilityIndicator
						availability={currentavailability}
						/>
				</Dropdown.Toggle>
				<Dropdown.Menu className="text-center">
					{
						Object.values(availMap)
							.filter(e => e.status !== currentavailability.status)
							.map(availability => (
								<AvailabilityOption
									key={availability.status}
									availability={availability}
									currentUser={currentUser}
									onGetUseravailability={this.props.onGetUseravailability}
								/>
							))
					}
				</Dropdown.Menu>
			</Dropdown >
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AvailabilitySelect);

