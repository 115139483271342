import React from 'react';
import { connect } from 'react-redux';

import ACTIONS from "../../actiontypes";

const mapStateToProps = state => ({
	goalList: state.common.goalList,
	statuses: state.goalmatrix.statuses,
});

const mapDispatchToProps = dispatch => ({
	onGetUserGoal: payload =>
		dispatch({ type: ACTIONS.USERGOAL.LOAD, payload }),
	onCreateUserGoal: description =>
		dispatch({ type: ACTIONS.GOAL.LOAD_FORM, description }),
});


class GoalTitle extends React.PureComponent
{
	get latestStatus()
	{
		const { user, statuses } = this.props;

		if (!(user && statuses))
			return null;

		const rgStatuses = Object.values(statuses);

		return rgStatuses.filter(gs => gs.user === user.id).reduce((acc, gs) =>
			acc ? (new Date(gs.updated_at) >= new Date(acc.updated_at) ? gs : acc) : gs
			, null
		);

	}

	render()
	{
		const { user, goalList, statuses, hastooltip } = this.props;

		if (!(user && goalList && statuses))
			return null;

		const latestStatus = this.latestStatus;
		const selectedgoal = latestStatus && goalList.find(g => g.id === latestStatus.goal);

		//		console.debug("render GoalTitle", selectedgoal, latestStatus, statuses);

		if (!selectedgoal)
			return null;


		if (hastooltip)
			return (
				<span title={selectedgoal.description}>
					{selectedgoal.description}
				</span>
			)
		else
			return (
				<React.Fragment>
					{selectedgoal.description}
				</React.Fragment>
			);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(GoalTitle);
