import React from 'react';
import agent from "../../agent";
import { connect } from 'react-redux';

import Form from 'react-bootstrap/Form';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';

import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import ImageUploader from "react-images-upload";

import ExtendedSet from "../../Utils/ExtendedSet";

import ToggleButton from "../ToggleButton";

import ListErrors from "../ListErrors";

import ProfileImage from '../Profile/Image';
import TeamImage from "../Team/Image";

import MainHeader from "../Header";

import { Save as FileStoreSave } from "../Filestore";

import ACTIONS from "../../actiontypes";

const mapStateToProps = state => ({
	currentUser: state.common.currentUser,
	personalDirectory: state.common.personalDirectory,
	teams: state.teams,
	userList: state.userman.userlist
});

const mapDispatchToProps = dispatch => ({
	onSubmitForm: payload =>
		dispatch({ type: ACTIONS.TEAM.CREATE, payload }),
	onDeleteTeam: payload =>
		dispatch({ type: ACTIONS.TEAM.DELETE, payload }),
	onAddMember: payload =>
		dispatch({ type: ACTIONS.TEAM.ADD_MEMBER, payload }),
	onRemoveMember: payload =>
		dispatch({ type: ACTIONS.TEAM.DEL_MEMBER, payload }),

});

function TeamMembers(props)
{
	return (
		<Container fluid="sm">
			<Row>
				{
					props.members.map(user =>
					{
						return (
							<Card key={user.id}>
								<Card.Body>
									{user.username}
									<Button
										className="close"
										onClick={() => props.onRemoveMember(user)}
										aria-label="Close">

										<span aria-hidden="true">&times;</span>
									</Button>
								</Card.Body>
							</Card>
						);
					})
				}
			</Row>
		</Container>
	);
}


class TeamForm extends React.Component
{
	cleanState()
	{
		return {
			name: '',
			description: '',
			private: false,
			members: {},
			goals: [],
			image: '',
		};
	}

	constructor()
	{
		super();

		this.state = this.cleanState();

		this.updateState = (field, extractfield) => ev =>
		{
			const fieldvalue = (extractfield && extractfield in ev.target) ? ev.target[extractfield] : ev.target.value;
			const state = this.state;
			const newstate = Object.assign({}, state, { [field]: fieldvalue });
			this.setState(newstate);
		};

		this.submitForm = ev =>
		{
			ev.preventDefault();
			const team = Object.assign({
				transient: this.props.inTransientMode
			}, this.state);


			if (this.isInUpdateMode)
			{
				const originalTeam = new ExtendedSet(Object.keys(this.props.initTeam.members));
				const modifiedTeam = new ExtendedSet(Object.keys(team.members));

				delete team.members;


				this.props.onSubmitForm(agent.Team.update(team)
					.then(result =>
					{
						const { id } = result.group;

						const newmembers = [...modifiedTeam.Difference(originalTeam)];
						const removedmembers = [...originalTeam.Difference(modifiedTeam)];

						console.debug("modifiedTeam", modifiedTeam);
						console.debug("originalTeam", originalTeam);
						console.debug("newmembers", newmembers);
						console.debug("removedmembers", removedmembers);

						removedmembers.forEach(userid =>
						{
							const user = this.props.initTeam.members[userid];
							this.props.onRemoveMember(agent.Team.remove(user._original_entry_id_));
						});

						newmembers.forEach(userid =>
						{
							this.props.onAddMember(agent.Team.add(id, userid));
						});


						return result;
					})
				);
			}
			else
			{
				const newteam = Object.keys(team.members);
				delete team.members;
				this.props.onSubmitForm(agent.Team.create(team)
					.then(result =>
					{
						const { id } = result.group;
						newteam.concat(this.props.currentUser.id).forEach(userid =>
						{
							console.debug("Add Member", id, userid);
							this.props.onAddMember(agent.Team.add(id, userid));
						});
						return result;
					}));

			}

		};


		this.filterBy = (option, state) =>
		{
			if (state.selected.length)
			{
				return true;
			}
			return option.username.toLowerCase().indexOf(state.text.toLowerCase()) > -1;
		}

		this.addmember = (select) =>
		{
			if (select && select[0])
			{
				const user = select[0];
				const newstate = Object.assign({}, this.state);
				newstate.members[user.id] = user;
				this.setState(newstate);
			}
		}

		this.removemember = (user) =>
		{
			//			console.debug("removemember", user);
			if (user)
			{
				const newstate = Object.assign({}, this.state);
				delete newstate.members[user.id];
				this.setState(newstate);
			}
		}

		this.isvalid = () => this.state.name.length > 2 && Object.keys(this.state.members).length > 0;

		this.addGoal = (goal) =>
		{
			if (goal && goal.length > 0)
			{
				const newstate = Object.assign({}, this.state);
				newstate.goals.push(goal);
				this.setState(newstate);
			}
		}

		this.onDrop = async (pictureFiles, pictureDataURLs) =>
		{
			const imgpath = await FileStoreSave(pictureFiles[0]);
			//agent.Auth.save({ image: imgpath });
			const team = Object.assign({}, this.state, { image: imgpath });
			agent.Team.update(team);
		}

	}

	DoDelete(ev)
	{
		if (window.confirm("Are you sure you want to delete this team permanently?"))
		{
			console.debug("Delete Team", this.state);
			this.props.onDeleteTeam(agent.Team.del(this.state.id));
		}

	}

	componentDidUpdate(prevProps)
	{
		//		console.debug("componentDidUpdate", this.props.initTeam != prevProps.initTeam, this.state, this.props.initTeam, prevProps.initTeam);

		if (this.props.initTeam !== prevProps.initTeam)
		{
			if (this.props.initTeam)
				this.setState({
					...this.state,
					...JSON.parse(JSON.stringify(this.props.initTeam))
				});
			else
				this.setState(this.cleanState());
		}
	}

	componentDidMount()
	{
		//		console.debug("componentDidMount");
		if (this.props.initTeam)
			this.setState({
				...this.state,
				...JSON.parse(JSON.stringify(this.props.initTeam))
			});

	}

	componentWillUnmount()
	{
		this.setState(this.cleanState());
	}

	get isInUpdateMode()
	{
		return !!this.props.initTeam;
	}

	render()
	{
		const { inTransientMode } = this.props;

		return (
			<Form onSubmit={this.submitForm}>
				{!inTransientMode &&
					<Form.Group as={Row}>
						<Form.Label column="lg" lg={2}>
							Image
					</Form.Label>

						<Col lg={10}>
							<ProfileImage
								className="ml-auto mr-auto w-50 pb-2"
							>
								<TeamImage
									image={this.state.image}
									name={this.state.name}
								/>
							</ProfileImage>

							<ImageUploader
								withIcon={false}
								buttonText="Upload New"
								onChange={this.onDrop}
								buttonClassName="btn btn-primary"
								withLabel={false}
								singleImage
								imgExtension={[".jpg", ".gif", ".png", ".gif"]}
								maxFileSize={2 * 1024 * 1024}
							/>
						</Col>
					</Form.Group>
				}
				<Form.Group>
					<Form.Row>
						<Form.Label column="lg" lg={2}>
							Name
					</Form.Label>
						<Col>
							<Form.Control
								size="lg"
								type="text"
								placeholder="Name"
								value={this.state.name}
								onChange={this.updateState('name')}
							/>
						</Col>
					</Form.Row>
					<br />

					{!inTransientMode &&
						<React.Fragment>
							<Form.Row>
								<Form.Label column="lg" lg={2}>
									Description
							</Form.Label>
								<Col>
									<Form.Control
										as="textarea"
										rows="4"
										placeholder="Description"
										value={this.state.description}
										onChange={this.updateState('description')}
									/>
								</Col>
							</Form.Row>
							<br />
						</React.Fragment>

					}
					<Form.Row>
						<Form.Label column="lg" lg={2}>
							Visibile to all?
					</Form.Label>
						<Col>
							<Form.Check
								type="switch"
								id="TeamPrivacyToggle_1"
								checked={this.state.private}
								label={this.state.private ? "Private" : "Public"}
								onChange={this.updateState('private', "checked")}
							/>
						</Col>
					</Form.Row>
					<br />
					{/* 					<Form.Row>
						<Form.Label column="lg" lg={2}>
							Goals
							</Form.Label>
						<Col>
							<GoalEdit
								goals={this.state.goals}
								addGoal={this.addGoal.bind(this)}
							/>
						</Col>
					</Form.Row>
					<br />
 */}					<Form.Row>
						<Form.Label column="lg" lg={2}>
							Members
						</Form.Label>
						<Col>
							<Typeahead
								filterBy={this.filterBy}
								id="Team-EntryForm-member"
								options={this.props.userList}
								labelKey="username"
								//onInputChange={(text, ev) => { console.debug(text, ev) }}
								onChange={this.addmember}
								placeholder="Choose a user...">

								{({ isMenuShown, toggleMenu }) => (
									<ToggleButton isOpen={isMenuShown} onClick={e => toggleMenu()} />
								)}
							</Typeahead>
							<TeamMembers
								onRemoveMember={this.removemember}
								members={Object.values(this.state.members)
								} />
						</Col>
					</Form.Row>
					<br />
				</Form.Group>
				<ButtonToolbar className="justify-content-between">
					<ButtonGroup>
						{this.isvalid() && (
							<Button disabled={!this.isvalid()} variant="primary" type="submit">
								{this.props.buttonLabel}
							</Button>
						)
						}
					</ButtonGroup>
					<ButtonGroup>
						{this.isInUpdateMode &&
							<Button
								variant="danger"
								onClick={this.DoDelete.bind(this)}
							>
								Delete
							</Button>
						}
					</ButtonGroup>
				</ButtonToolbar>
			</Form>

		);
	}

}


class TeamEditorInner extends React.PureComponent
{
	render()
	{
		//		console.debug("Editing", this.props);
		const { currentUser, teams, userList,
			match: {
				params: { id:paramId } = {}
			} = {},
			title, inTransientMode, updateId, onSubmitOveride
		} = this.props;

		const id = updateId|| paramId;

		if (!(currentUser && userList))
			return null;

		const team = id && teams.details && { ...teams.details[id] };
		if (team)
		{
			if (!teams.memberList[id])
				return null;

			team.members = teams.memberList[id]
				.filter(member => currentUser.id !== member.user)
				.map(member => ({ ...userList.find(e => e.id === member.user), _original_entry_id_: member.id }))
				.reduce((acc, user) => 
				{
					if (user) acc[user.id] = user;
					return acc;
				}, {})
				;
			//console.debug("Editing", userList, teams, team);
		}

		const fInMeeting = inTransientMode || (team && team.transient);
		const modifyLabel = (updateId||team) ? "Update" : "Create";
		const groupTitle = fInMeeting?"Meeting":"Team";

		return (
			<Container fluid className="page">
				<Col xs={12} md={{ span: 10, offset: 1 }}>
					{!inTransientMode && <h1 className="text-xs-center">
						{title ? title : `${modifyLabel} ${groupTitle}`}
					</h1>}
	
					<ListErrors errors={this.props.errors}></ListErrors>
					<TeamForm
						currentUser={currentUser}
						onSubmitForm={onSubmitOveride || this.props.onSubmitForm}
						onAddMember={this.props.onAddMember}
						onRemoveMember={this.props.onRemoveMember}
						onDeleteTeam={this.props.onDeleteTeam}
						userList={userList}
						initTeam={team}
						buttonLabel={modifyLabel}
						inTransientMode={fInMeeting}
					/>
				</Col>
			</Container>
		);
	}
}

export const TeamEditorEmbeddable = connect(mapStateToProps, mapDispatchToProps)(TeamEditorInner);

export default function(props)
{
	return (
		<div className="dskly-team-page">
			<MainHeader {...props} />
			<TeamEditorEmbeddable {...props} />
		</div>
	);
}

 

