import React from 'react';
import agent from "../../agent";
import { connect } from 'react-redux';


import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';


import UserCard from "../User/Card";
import TeamCard from "../Team/Card";


import ACTIONS from "../../actiontypes";


const mapStateToProps = state => ({
	...state.userman,
	moodList: state.common.moodList,
	currentgoals: state.common.currentgoals,
	goalList: state.common.goalList,
	availabilityList: state.common.availabilityList,
	personalDirectory: state.common.personalDirectory,
	personalTeams: state.common.personalTeams,
	teams:state.teams,
	currentUser:state.common.currentUser
});

const mapDispatchToProps = dispatch => ({
	onGetPersonalUserList: payload =>
		dispatch({ type: ACTIONS.PERSONAL_DIRECTORY.LOAD, payload }),
	onGetPersonalTeamList: payload =>
		dispatch({ type: ACTIONS.PERSONAL_DIRECTORY.LOAD_GROUPS, payload }),
});


class UserDesk extends React.PureComponent
{
	constructor()
	{
		super();

		this.onDelAction = (user) =>
		{
			agent.PersonalDirectory.del(user.id);
		}
		this.onDelTeamAction = (team) =>
		{
			agent.PersonalTeamDirectory.del(team.id);
		}

		this.onDelMeetingAction=(meeting)=>
		{
			agent.Team.del(meeting.id);
		}

		this.onRemoveSelfFromTeam = (entry)=>(team)=>
		{
			if (entry && window.confirm("Are you sure you want to exit?"))
			{
				agent.Team.remove(entry.id);
			}
		}
	}

	componentDidMount()
	{
		this.props.onGetPersonalUserList(agent.PersonalDirectory.list());
		this.props.onGetPersonalTeamList(agent.PersonalTeamDirectory.list());
	}

	render()
	{
		const { personalTeams, personalDirectory, teams, currentUser, presence} = this.props;

		const renderUser = (user) =>
		{

			const userpresence = presence[user.id];
			const fIsOnline = userpresence && userpresence.length > 0;

			return (
				<UserCard
					key={user.id}
					user={user}
					mood={this.props.moods[user.id]}
					moodList={this.props.moodList}
					currentgoals={this.props.currentgoals}
					goalList={this.props.goalList}
					availabilityList={this.props.availabilityList}
					actionSymbol="&times;"
					onAction={this.onDelAction}
					currentUser={currentUser}
					fIsOnline={fIsOnline}

				/>
			);
		}


		const renderTeam = ([teamid, userlist]) =>
		{
			if (!(teamid && teams.details && teams.details[teamid]))
				return null;

			const fIsInPersonalTeam = personalTeams.includes(parseInt(teamid));
			const team = teams.details[teamid];
			const fAmTeamOwner = team.owner === currentUser.id;
			
			const actionSymbol = fIsInPersonalTeam ? "×" : (!fAmTeamOwner ? "–" : (team.transient ?"×":null));

			const _original_entry_ = userlist.find(e=> e.user===currentUser.id);
			//if (!_original_entry_)
			//	return null;

			const onAction = (team.transient && fAmTeamOwner)? this.onDelMeetingAction: (fIsInPersonalTeam ? this.onDelTeamAction : this.onRemoveSelfFromTeam(_original_entry_));

			return (
				<TeamCard
					key={teamid}
					members={userlist}
					team={team}
					currentUser={currentUser}
					globalDirectory={this.props.userlist}
					actionSymbol={actionSymbol}
					onAction={onAction}
				/>
			)
		}

		const myTeam = ([teamid, userlist]) =>
		{
			if (!(teamid && teams.details && teams.details[teamid]))
				return false;

			return teams.details[teamid].owner === currentUser.id|| personalTeams.includes(parseInt(teamid)) || userlist.some(u=> u.user === currentUser.id);
		}


//		const rgMeetings = teams.filter(team=>team.transient);
//		const rgRegularTeams = teams.filter(team => team.transient);

		const rgMyTeams = Object.entries(teams.memberList)
			.filter(myTeam);

		const rgMyTeamsInMeeting = rgMyTeams.filter(([id]) => teams.details[id] && teams.details[id].transient);
		const rgMyTeamsNotInMeeting = rgMyTeams.filter(([id]) => teams.details[id] && !teams.details[id].transient);


		console.debug("personal", rgMyTeams, rgMyTeamsInMeeting, rgMyTeamsNotInMeeting);
		if (!currentUser || !this.props.userlist || !personalDirectory)
			return (
				<span className="fa fa-spinner fa-spin"></span>
			);

		return (
			<Container fluid className="dskly-user-container dskly-userdesk">
				<Row>
					{
						rgMyTeamsInMeeting.map(renderTeam)
					}

					{
						personalDirectory
							.map(id => this.props.userlist.find(u=>id===u.id))
							.filter(e => !!e) //remove bogus entries
							.map(renderUser)
					}
					{
						rgMyTeamsNotInMeeting.map(renderTeam)
					}
				</Row>
			</Container>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDesk);

