
import ACTIONS from "../actiontypes";
import MAGICUSERS from "../MagicUsers/Konst";

import {CallUrl} from "../Utils/Communicator";

const defaultState = {
	appName: "DSKLY",
	token: null,
	goalList: [],
	currentgoals: {},
	availabilityList: {},
	personalDirectory: [],
	personalTeams: [],

};

export default function (state = defaultState, action)
{
	switch (action.type)
	{
		case ACTIONS.APP.LOAD:
			return {
				...state,
				token: action.token || null,
				appLoaded: true,
				currentUser: action.payload ? action.payload.user : null
			};

		case MAGICUSERS.ACTION.CONNECTED:
			return {
				...state,
				uuidSession: action.uuidSession,
			}


		case ACTIONS.MOOD.LOAD_LIST:
			return {
				...state,
				moodList: action.payload ? action.payload.moods : null,
			};
/*
		case ACTIONS.COMMUNICATOR.MAKECALL:
		case ACTIONS.COMMUNICATOR.JOIN:
			{
				const { cdr,user, connection } = action;
				if (cdr && user)
				{
					const existingcall = state.activeCalls[cdr.address] || {cdr,sessions:[]};

					return {
						...state,
						activeCalls: {
							...state.activeCalls,
							[cdr.address]:{
								...existingcall,
								cdr,
								connection,
								sessions: [...(new Set([...existingcall.sessions, user.id]))]
							} ,
						},
					};
				}
						
			}
		break;

		case ACTIONS.COMMUNICATOR.ANSWER:
			{
				const { cdr, user } = action;
				if (cdr && user)
				{
					const existingcall = state.activeCalls[cdr.address] || { cdr, sessions: [] };

					return {
						...state,
						activeCalls: {
							...state.activeCalls,
							[cdr.address]: {
								...existingcall,
								cdr,
								sessions: [...(new Set([...existingcall.sessions, user.id]))]
							},
						},
						redirectTo: CallUrl(cdr)
					};
				}

			}
			break;

		case ACTIONS.COMMUNICATOR.LEAVE:
			{
				const { cdr, user, connection } = action;
				if (cdr && user && connection)
				{
					console.debug("LEAVE", cdr, user);
					const existingcall = state.activeCalls[cdr.address] || { cdr, sessions: [] };

					const newsessions = existingcall.sessions.filter(id => id != user.id);
					
					const activeCalls = {...state.activeCalls};
					if (newsessions.length<=0)
						delete activeCalls[cdr.address];
					else
						activeCalls[cdr.address] = { ...existingcall, sessions:newsessions};

					return {
						...state,
						activeCalls,
					};
				}

			}
			break;
*/
		case ACTIONS.GOAL.LOAD_LIST:
			return {
				...state,
				goalList: action.payload ? action.payload.goals.filter(g => !g.defunct) : null,
			};

		case ACTIONS.GOAL.LOAD_FORM:
			return {
				...state,
				redirectTo: "goal",
			};

		case ACTIONS.APP.REDIRECT:
			return { ...state, redirectTo: null };

		case ACTIONS.APP.LOGOUT:
			return { ...state, redirectTo: "/", token: null, currentUser: null };

		case ACTIONS.SETTINGS.SAVED:
			return {
				...state,
				redirectTo: action.error ? null : "/",
				currentUser: action.error ? null : action.payload.user
			};
		case ACTIONS.ARTICLE.SUBMITTED:
			return {
				...state,
				redirectTo: `/article/${action.payload.article.slug}`
			};

		case ACTIONS.APP.LOGIN:
		case ACTIONS.APP.REGISTER:
			return {
				...state,
				redirectTo: action.error ? null : "/",
				token: action.error ? null : action.payload.user.token,
				currentUser: action.error ? null : action.payload.user
			};

		case MAGICUSERS.EVENT.USERMANAGER:
			if (!action.error)
			{
				const { user, cmd } = action.payload;
				if (cmd === "update" && user.id === state.currentUser.id)
					return {
						...state,
						currentUser: { ...state.currentUser, ...user }
					}
			}
			break;

		case ACTIONS.ARTICLE.DELETE:
		case ACTIONS.GOAL.CREATE:
		case ACTIONS.TEAM.CREATE:
		case ACTIONS.USER.DELETE:
		case ACTIONS.TEAM.DELETE:
			return { ...state, redirectTo: "/" };

		case ACTIONS.PERSONAL_DIRECTORY.LOAD:
			return {
				...state,
				personalDirectory: action.error ? [] : action.payload.users.map(u => u.user)
			};

		case ACTIONS.PERSONAL_DIRECTORY.LOAD_GROUPS:
			return {
				...state,
				personalTeams: action.error ? [] : action.payload.groups.map(u => u.group)
			};

		case MAGICUSERS.EVENT.NEWGOAL:
			if (action.error)
				return state;
			else
			{
				const { goal } = action.payload;

				return {
					...state,
					goalList: state.goalList.filter(g => g.id !== goal.id).concat(goal.defunct ? [] : action.payload.goal)
				};
			}

		case MAGICUSERS.EVENT.NEWUSERGOAL:
			if (action.error)
				return state;
			else
			{
				const goal = action.payload.goal;
				return {
					...state,
					currentgoals: {
						...state.currentgoals,
						[goal.user]: goal
					},
				};
			}

		case ACTIONS.AVAILABILITY.LOAD_INDEX:
			if (!action.error)
			{
				const allavailable = action.payload.allavailable.reduce((rg, available)=>{
					rg[available.user] = available;
					return rg;
				}, {});

				return {
					...state,
					availabilityList: {
						...state.availabilityList,
						...allavailable
					},
				};

			}
			break;

		case MAGICUSERS.EVENT.NEWAVAILABILITY:
			if (action.error)
				return state;
			else
			{
				const available = action.payload.available;
				return {
					...state,
					availabilityList: {
						...state.availabilityList,
						[available.user]: available
					},
				};
			}

		case MAGICUSERS.EVENT.NEWPERSONALUSER:
			if (action.error)
				return state;
			else
			{
				const { user, cmd } = action.payload;
				const userid = user.user;

				if (cmd === "create") 
				{
					const rgnew = state.personalDirectory.includes(userid) ? state.personalDirectory : state.personalDirectory.concat(userid);

					return {
						...state,
						personalDirectory: rgnew,
					};
				}
				else if (cmd === "delete")
				{
					const rg = Array.from(state.personalDirectory);
					const i = rg.indexOf(parseInt(userid));
					if (~i)
					{
						rg.splice(i, 1);
						return {
							...state,
							personalDirectory: rg
						}
					}

					return state;
				}

			}
			break;

		case MAGICUSERS.EVENT.PERSONALGROUP:
			if (action.error)
				return state;
			else
			{
				const { user, cmd } = action.payload;
				const groupid = user.group;

				if (cmd === "create") 
				{
					const rgnew = state.personalTeams.includes(groupid) ? state.personalTeams : state.personalTeams.concat(groupid);

					return {
						...state,
						personalTeams: rgnew,
					};
				}
				else if (cmd === "delete")
				{
					const rg = Array.from(state.personalTeams);
					const i = rg.indexOf(parseInt(groupid));
					if (~i)
					{
						rg.splice(i, 1);
						return {
							...state,
							personalTeams: rg
						}
					}

					return state;
				}

			}
			break;

		default:
			return state;
	}

	return state;
}