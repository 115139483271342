
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Nav from "react-bootstrap/Nav";

import ArticleList from '../ArticleList';
import agent from "../../agent";

import ACTIONS from "../../actiontypes";
import FEED from "../../feedtypes";

import Notifications from "../Notification";


function CompanyFeedTab(props)
{
	if (props.token)
	{
		function clickHandler(ev)
		{
			ev.preventDefault();
			props.onTabClick(FEED.COMPANY, agent.Articles.all(0, FEED.COMPANY));
		}

		return (
			<li className="nav-item">
				<button
					className={props.tab === FEED.COMPANY ? 'nav-link active' : 'nav-link'}
					onClick={clickHandler}>
					Company
					</button>
			</li>
		)
	}

	return null;
}

function GlobalFeedTab(props)
{
	function clickHandler(ev)
	{
		ev.preventDefault();
		props.onTabClick(FEED.GLOBAL, agent.Articles.all());
	}

	return (
		<li className="nav-item">
			<button
				className={props.tab === FEED.GLOBAL ? 'nav-link active' : 'nav-link'}
				onClick={clickHandler}>
				All
					</button>
		</li>
	)
}

function NotificationFeedTab(props)
{
	function clickHandler(ev)
	{
		ev.preventDefault();
		props.onTabClick(FEED.NOTIFICATION);
	}

	return (
		<li className="nav-item">
			<button
				className={props.tab === FEED.NOTIFICATION ? 'nav-link active' : 'nav-link'}
				onClick={clickHandler}>
				<i className="ion-flash" />
			</button>
		</li>
	)
}

function ArticleSearchForm(props)
{
	const { searchTerm, onNewSearchArticlesTermFilter, clearSearchTerm } = props;

	return (
		<Form onSubmit={e => e.preventDefault()}>
			<InputGroup>
				<Form.Control
					placeholder="Search articles..."
					value={searchTerm}
					onInput={e => onNewSearchArticlesTermFilter(e.target.value)}
				/>
				{searchTerm && searchTerm.length &&
					<InputGroup.Append>
						<Button
							variant="outline-secondary"
							onClick={clearSearchTerm}
						><span aria-hidden="true">&times;</span>
						</Button>
					</InputGroup.Append>
				}

			</InputGroup>
		</Form>
	);
}

const mapStateToProps = state => ({
	...state.articleList,
	token: state.common.token,
	currentUser: state.common.currentUser,
});

const mapDispatchToProps = dispatch => ({
	onSetPage: (payload, page) =>
		dispatch({
			type: ACTIONS.PAGE.SET,
			page,
			payload
		})
	, onTabClick: (tab, payload) =>
		dispatch({ type: ACTIONS.APP.CHANGE_TAB, tab, payload })
	, onNewSearchArticles: (tab, payload) =>
		dispatch({ type: ACTIONS.APP.CHANGE_TAB, tab, payload })
});

const MainView = props =>
{
	const { currentUser, tab } = props;

	const [searchTerm, setSearchTerm] = useState("");

	const onSetPage = page => props.onSetPage(
		isValidSearchTerm(searchTerm) ? agent.Articles.search(page, searchTerm, props.tab) : agent.Articles.all(page, props.tab)
		, page);

	function isValidSearchTerm(term)
	{
		return term && term.length > 0;
	}

	const onNewSearchArticlesTermFilter = (newterm) =>
	{
		console.debug("search", newterm);
		setSearchTerm(newterm);
		if (isValidSearchTerm(newterm))
			props.onNewSearchArticles(props.tab, agent.Articles.search(0, newterm, tab));
	};

	const clearSearchTerm = () =>
	{
		setSearchTerm("");
		loadall();
	}

	function loadall()
	{
		console.debug("search");
		props.onTabClick(FEED.GLOBAL, agent.Articles.all());

	}

	useEffect(() =>
	{
		if (isValidSearchTerm(searchTerm))
		{

		}
		else
			loadall();
	}, [searchTerm]);

	const fShowNotifications = tab === FEED.NOTIFICATION;

	const fCanEdit = !tab || (tab === FEED.COMPANY && currentUser.roles.includes("CompanyFeedPosting"));

	const fShowFeeds = !isValidSearchTerm(searchTerm);


	return (
		<div className="dskly-article-container h-100 d-flex flex-column">
			{!fShowNotifications &&
				<Container fluid className="py-1">
					<ArticleSearchForm
						searchTerm={searchTerm}
						clearSearchTerm={clearSearchTerm}
						onNewSearchArticlesTermFilter={onNewSearchArticlesTermFilter} />

				</Container>
			}

			<div className="flex-fill w-100 overflow-auto">
				{fShowFeeds &&
					<React.Fragment>
						<div className="feed-toggle">
							<ul className="nav nav-pills outline-active">
								<CompanyFeedTab
									token={props.token}
									tab={props.tab}
									onTabClick={props.onTabClick}
								/>

								<GlobalFeedTab
									tab={props.tab}
									onTabClick={props.onTabClick}
								/>

								<NotificationFeedTab
									tab={props.tab}
									onTabClick={props.onTabClick}
								/>

							</ul>
						</div>
						{fCanEdit &&
							<Nav className="dskly-article-nav bg-primary justify-content-center" >
								<Nav.Item>
									<Link to="/editor" className="nav-link">
										<i className="ion-compose" />
									</Link>
								</Nav.Item>

							</Nav>

						}

					</React.Fragment>
				}
				{fShowNotifications ? (<Notifications />) :
					(
						<React.Fragment>
							<ArticleList
								articles={props.articles}
								articlesCount={props.articlesCount}
								currentPage={props.currentPage}
								onSetPage={onSetPage}
							/>
							<Notifications
								className="position-fixed h-100"
								autohide={true}
							/>
						</React.Fragment>


					)

				}

			</div>

		</div>

	);
};

export default connect(mapStateToProps, mapDispatchToProps)(MainView);