import agent from "./agent";
import ACTIONS from "./actiontypes";

import Token from "./Utils/Token";

const promiseMiddleware = store => next => action =>
{
	if (isPromise(action.payload))
	{
		store.dispatch({ type: ACTIONS.ASYNC.START, subtype: action.type });

		action.payload.then(
			res =>
			{
				action.payload = res;
				store.dispatch(action);
			},
			error =>
			{
				console.debug(action, error);
				action.error = true;
				action.payload = error.response.body;
				store.dispatch(action);
			}
		)
		return;
	}
	next(action);
}

function isPromise(v)
{
	return v && typeof v.then === 'function';
}

const localStorageMiddleware = store => next => action =>
{

	function logout()
	{
		Token.clear();
		agent.setToken(null);
	}

	switch (action.type)
	{
		case ACTIONS.APP.REGISTER:
		case ACTIONS.APP.LOGIN:
			if (action.error)
			{
				console.warn("Forcible logout", action.error);
				logout();
			}
			else
			{
				Token.set(action.payload.user.token);
				agent.setToken(action.payload.user.token);
			}
			break;


		case ACTIONS.APP.LOGOUT:
			logout();
			break;

		default:
			break;
	}

	next(action);
}

export
{
	localStorageMiddleware,
	promiseMiddleware
};