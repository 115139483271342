import ACTIONS from "../actiontypes";
import MAGICUSERS from "../MagicUsers/Konst";


export default function (state = { statuses: [] }, action)
{
	switch (action.type)
	{
		case ACTIONS.GOALMATRIX.LOAD_USER_STATUSES:
			if (!action.error)
				return {
					...state,
					statuses: action.payload.goals.reduce((statuses, gs) => { statuses[gs.id] = gs; return statuses; }, { ...state.statuses })
				}

			break;

		case MAGICUSERS.EVENT.GOALMATRIX:
			if (!action.error)
			{
				const { goal, cmd } = action.payload;
	
				if (cmd === "create")
				{
					return {
						...state,
						statuses: {
							...state.statuses,
							[goal.id]: goal
						}
					};
				}
				else if (cmd === "delete")
				{
					const statuses = {
						...state.statuses
					};
					delete statuses[goal.id];

					return {
						...state,
						statuses
					};
				}
				else if (cmd === "bulkdelete")
				{
					return {
						...state,
						statuses:state.statuses.filter(g=> g.goal != goal)
					};
				}
			}
			break;

		default:
			return state;
	}

	return state;
}