import React from 'react';
import agent, { FileStorePath } from '../agent';



export async function Upload(jsfile)
{
	console.debug("Save file", jsfile);

	const result = await agent.FileStore.create("myfile", jsfile);
	console.debug(result);
	return result;
}

export async function Save(jsfile)
{
	const result = await Upload(jsfile);
	return FileStorePath(result.entry.path);
}