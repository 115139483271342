import React from 'react';

import FillImage from "../FillImage";


export default function(props)
{
	return (
		<FillImage
			emptyContent={<h1><i className={props.inMeeting ? "ion-calendar":"ion-android-contacts"} /></h1>}
			{...props}
		/>
	);
}