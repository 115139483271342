import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import { Communicator, Reporting } from "../MagicUsers";

import ProfileImage from './Profile/Image';
import { CallUrl } from "../Utils/Communicator";

import ACTIONS from "../actiontypes";

import "animate.css";

const mapStateToProps = state => ({
	currentUser: state.common.currentUser
});

const mapDispatchToProps = dispatch => ({
	// onMakeCall: (cdr, user) =>
	// 	dispatch({ type: ACTIONS.COMMUNICATOR.MAKECALL, cdr, user }),
});

function Dskly(props)
{
	const { className, cdr, callLink, profileLink, profileImage, profileStyle, onAction, actionSymbol, chatLink, currentUser, fActiveCall, fActiveChat, fShakeCard } = props;

	return (
		<Card className={`${className} dskly-card text-center pt-3 `} >
			{actionSymbol && onAction &&
				<Button onClick={e => onAction()} variant="primary" className="action-button">{actionSymbol}</Button>
			}
			<ProfileImage
				{...props}
				className={`ml-auto mr-auto w-50 pb-2 ${fShakeCard||fActiveCall || fActiveChat ? "animate__animated animate__headShake animate__infinite" : ""}`}>
				{profileImage}
			</ProfileImage>

			<Card.Header className="text-center text-truncate">
				{props.headerContent}
			</Card.Header>

			{props.children}

			<small className="text-center text-truncate">
				{props.content}
			</small>

			<Card.Footer className="text-center">
				{props.emailLink &&
					<Card.Link
						href={props.emailLink}
						target="_blank"
						onClick={() =>
						{
							Reporting("Email launched", { to: cdr.address });
						}}
					> <i className="ion-ios-email-outline"></i>
					</Card.Link>
				}

				<Card.Link
					href={chatLink}
					rel="opener"
					target="_blank"
				><i className={`ion-ios-chatboxes-outline ${fActiveChat ? "d-inline-block animate__animated animate__tada animate__infinite" : ""}`} />
				</Card.Link>
				{cdr &&
					<Card.Link
						href={CallUrl(cdr.address)}
						rel="opener"
						target="_blank"
						onClick={e =>
						{
							// Communicator("MakeCall", cdr);
							// onMakeCall(cdr, currentUser);
						}
						}
					><i className={`ion-android-people ${fActiveCall ? "d-inline-block animate__animated animate__tada animate__infinite" : ""}`} /></Card.Link>
				}

			</Card.Footer>

		</Card >
	);

}

export default connect(mapStateToProps, mapDispatchToProps)(Dskly);

