import React from 'react';
import agent from "../../agent";
import { connect } from 'react-redux';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

import MAGICUSERS from "../../MagicUsers/Konst";


import ACTIONS from "../../actiontypes";

import UserCard from "../User/Card";
import TeamCard from "../Team/Card";

const mapStateToProps = state => ({
	...state.userman,
	moodList: state.common.moodList,
	currentgoals: state.common.currentgoals,
	goalList: state.common.goalList,
	availabilityList: state.common.availabilityList,
	personalDirectory: state.common.personalDirectory,
	personalTeams: state.common.personalTeams,
	teams: state.teams,
	currentUser: state.common.currentUser
});

const mapDispatchToProps = dispatch => ({
	onGetUserList: payload =>
		dispatch({ type: ACTIONS.USERMAN.LOADED, payload }),
	onGetTeamList: payload =>
		dispatch({ type: ACTIONS.TEAM.LOAD_PUBLIC, payload }),
	onGetTeamUsers: payload =>
		dispatch({ type: ACTIONS.TEAM.LOAD_USERS, payload }),
	onGetTeamDetails: payload =>
		dispatch({ type: ACTIONS.TEAM.LOAD_DETAILS, payload }),
});

function SearchForm(props)
{

	return (
		<Form onSubmit={e => e.preventDefault()}>
			<Form.Row>
				<Col>
					<Form.Control
						placeholder="Search person, team or meeting..."
						onInput={e => props.onNewSearchTermFilter(e.target.value)}
					/>
				</Col>
			</Form.Row>
		</Form>
	);
}

function BouncyAnimator(elementName, speed)
{
	let direction = 1;

	let fAnimating = true;

	let lastTime;
	let cumulativeFraction = 0;
	function step(timestamp)
	{
		if (lastTime === undefined)
			lastTime = timestamp;

		const delta = timestamp - lastTime;
		const scrollamount = cumulativeFraction + ((delta * speed) / 1000.0);

		if (scrollamount >= 1)
		{
			const container = document.getElementById(elementName);
			if (container && fAnimating)
			{
				const currentvalue = container.scrollLeft;
				const actualScroll = Math.floor(scrollamount);
				cumulativeFraction = scrollamount - actualScroll;
				container.scrollLeft += direction * actualScroll;
				//				console.debug("animation", delta, scrollamount, actualScroll, cumulativeFraction, currentvalue, container.scrollLeft);

				if (container.scrollLeft === currentvalue)
				{
					direction = -direction;
				}
			}
			lastTime = timestamp;

		}

		animationId = requestAnimationFrame(step);
	}
	let animationId = requestAnimationFrame(step);

	return {
		Start()
		{
			fAnimating = true;
		}
		,
		Pause()
		{
			fAnimating = false;
		}
		,
		Stop()
		{
			cancelAnimationFrame(animationId);
			animationId = 0;
		}
	};
}

class Carousel extends React.PureComponent
{
	constructor()
	{
		super();

		this.state = {
			filterTerm: ""
		};

		this.onNewSearchTermFilter = (newterm) =>
		{
			if (newterm && newterm.length > 0)
			{
				console.debug("New Searh Term", newterm);
			}
			else
			{
				console.debug("Clear Search Term");

			}

			this.setState({
				...this.state,
				filterTerm: newterm
			})
		}

		this.onAddAction = (user) =>
		{
			agent.PersonalDirectory.add(user.id);
		}

		this.onTeamAddAction = (team) =>
		{
			agent.PersonalTeamDirectory.add(team.id);
		}

		this.onDelMeetingAction = (meeting) =>
		{
			agent.Team.del(meeting.id);
		}

		this.animator = BouncyAnimator("carousel_1", 10);
	}

	componentWillUnmount()
	{
		this.animator.Stop();
	}

	render()
	{
		const { personalTeams, personalDirectory, teams, currentUser, presence } = this.props;

		const renderUser = (user) =>
		{
			const fIsInPersonalDir = personalDirectory.includes(user.id);

			const userpresence = presence[user.id];
			const fIsOnline = userpresence && userpresence.length > 0;

			return (
				<UserCard
					className="bg-info text-white"
					key={user.id}
					user={user}
					mood={this.props.moods[user.id]}
					moodList={this.props.moodList}
					currentgoals={this.props.currentgoals}
					goalList={this.props.goalList}
					availabilityList={this.props.availabilityList}
					actionSymbol={fIsInPersonalDir ? null : "+"}
					onAction={this.onAddAction}
					currentUser={currentUser}
					fIsOnline={fIsOnline}
				/>
			);
		}



		const renderTeam = ([teamid, userlist]) =>
		{
			if (!(teamid && teams.details && teams.details[teamid]))
				return null;

			const fIsInPersonalTeam = personalTeams.includes(parseInt(teamid));
			const team = teams.details[teamid];
			const fAmTeamOwner = team.owner === currentUser.id;
			const fAmTeamMember = !!userlist.find(e => e.user === currentUser.id);

			const fMyMeeting = (team.transient && fAmTeamOwner);

			const actionSymbol = fMyMeeting ? "×": 
				((fIsInPersonalTeam || fAmTeamOwner || fAmTeamMember) ? null : "+");

			const onAction = fMyMeeting ? this.onDelMeetingAction : this.onTeamAddAction;

			return (
				<TeamCard
					key={teamid}
					members={userlist}
					team={team}
					currentUser={currentUser}
					globalDirectory={this.props.userlist}
					actionSymbol={actionSymbol}
					onAction={onAction}
				/>
			)
		}

		if (!this.props.userlist)
			return (
				<div className="">Empty nest</div>
			);


		const matchterm = this.state.filterTerm.toUpperCase();

		const filterfunc = term => term.toUpperCase().includes(matchterm);

		const searchlist = this.props.userlist.reduce((rg, user) =>
		{
			if (user.id !== currentUser.id && filterfunc(user.username))
				rg.push(user);
			return rg;
		}, []);

		const teamlist = Object.entries(teams.memberList)
			.filter(([teamid]) =>
			{
				const team = teams.details[teamid];

				return team &&
					!team.private &&
					filterfunc(team.name)
					
			}
			);


		if (matchterm.length > 0)
			this.animator.Pause();
		else
			this.animator.Start();

		console.debug("Carousel render", personalTeams, teamlist);

		return (
			<div className="h-100 d-flex flex-column">
				<div className="py-1">
					<SearchForm onNewSearchTermFilter={this.onNewSearchTermFilter} />
				</div>
				<div
					onMouseLeave={this.animator.Start}
					onMouseOver={this.animator.Pause}
					id="carousel_1"
					className="flex-fill d-flex dskly-user-container dskly-carousel">
					{!(searchlist.length || teamlist.length) &&
						(
							<Alert variant="warning" className="w-100">
								No Match!
							</Alert>)
					}
					{searchlist.map(renderUser)}
					{teamlist.map(renderTeam)}
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Carousel);

