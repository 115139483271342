import ACTIONS from "../actiontypes";

export default function (state = {}, action)
{
	switch (action.type)
	{
		case ACTIONS.EDITOR.PAGE_LOADED:
			return {
				...state,
				articleSlug: action.payload ? action.payload.article.slug : '',
				title: action.payload ? action.payload.article.title : '',
				description: action.payload ? action.payload.article.description : '',
				body: action.payload ? action.payload.article.body : '',
				attachment: action.payload ? action.payload.article.attachment : undefined,
			};

		case ACTIONS.PAGE.HOME_LOADED:
		case ACTIONS.APP.CHANGE_TAB:
			return {
				...state,
				feed: action.tab ? action.tab:null
			}

		case ACTIONS.EDITOR.PAGE_UNLOADED:
			return {};

		case ACTIONS.ARTICLE.SUBMITTED:
			return {
				...state,
				inProgress: null,
				errors: action.error ? action.payload.errors : null
			};

		case ACTIONS.ASYNC.START:
			if (action.subtype === ACTIONS.ARTICLE.SUBMITTED)
				return { ...state, inProgress: true };
			break;

		case ACTIONS.EDITOR.UPDATE_FIELD:
			return {
				...state,
				[action.key]: action.value
			};
		default:
			return state;

	}
	return state;


}