import React from 'react';
import { Link } from 'react-router-dom';

import MoodSelect from "./Mood";
import AvailSelect from "./Available";

import ProfileImage from '../Profile/Image';

class UserInfo extends React.PureComponent
{
	render()
	{
		const user = this.props.currentUser;

		if (user)
		{
			return (
				<div className="card justify-content-center text-center border-success">


					<AvailSelect />
					<ProfileImage
						className="ml-auto mr-auto w-75 pb-1"
						profileLink={`/@${user.email}`}

					>
						<img src={user.image || 'https://static.productionready.io/images/smiley-cyrus.jpg'} className="user-pic" alt={user.username} />
					</ProfileImage>
					{user.username}

					<MoodSelect />

				</div>
			);
		}

		return null;
	}
}

export default UserInfo;