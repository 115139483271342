import React from 'react';
import Button from 'react-bootstrap/Button';

export default ({ isOpen, onClick }) => (
	<Button
		className="toggle-button"
		onClick={onClick}
		onMouseDown={e =>
		{
			// Prevent input from losing focus.
			e.preventDefault();
		}}>
		{isOpen ? '▲' : '▼'}
	</Button>
);
