import React from 'react';

import FillImage from "../FillImage";


export default function (props)
{
	return (
		<FillImage
			emptyContent={<h1><i className="ion-person" /></h1>}
			{...props}
		/>
	);
}