import ACTIONS from "../actiontypes";
import MAGICUSERS from "../MagicUsers/Konst";


export default function (state = { moods: {}, presence: {} }, action)
{
	switch (action.type)
	{
		case ACTIONS.ASYNC.START:
			return {
				...state,
				inProgress: true,
			};

		case ACTIONS.USERMAN.LOADED:
			return {
				...state,
				inProgress: false,
				userlist: action.error ? null : action.payload.users
			}

		case MAGICUSERS.EVENT.PRESENCE:
			if (!action.error)
			{
				const { args, cmd } = action.payload;


				switch (cmd)
				{

					case "AllOthers":
						{
							const newpresence = args[0].reduce((rg, [sessionid, userid]) =>
							{
								const existingentry = rg[userid] || [];
								rg[userid] = [
									...existingentry,
									...[sessionid]
								]
									;

								return rg;
							}, {});


							return {
								...state,
								presence: newpresence
							}
						}
						break;
					case "connection":
						{
							const [sessionid, userid] = args;
							const existingentry = state.presence[userid] || [];
							const newentry = [
								...existingentry,
								...[sessionid],
							];

							const newpresence = {
								...state.presence,
								[userid]: [...(new Set(newentry))]
							};


							const newstate = {
								...state,
								presence: newpresence
							};

							console.debug("connection presnese", newpresence, sessionid, userid, existingentry, newentry);

							return newstate;
						}
						break;
					case "disconnect":
						{
							const [sessionid, userid] = args;
							const existingentry = state.presence[userid] || [];
							return {
								...state,
								presence: {
									...state.presence,
									[userid]: existingentry.filter(e => e !== sessionid)
								}
							};
						}
						break;
				}
			}
			break;

		case MAGICUSERS.EVENT.USERMANAGER:
			if (!action.error)
			{
				const { user, cmd } = action.payload;

				switch (cmd)
				{
					case "remove":
						{
							return {
								...state,
								userlist: state.userlist.filter(u => u.id !== user.id)
							};
						}
						break;

					case "create":
						{
							return {
								...state,
								userlist: state.userlist.concat([user])
							};
						}
						break;
					case "update":
						{
							return {
								...state,
								userlist: state.userlist.map(u => u.id !== user.id ? u : { ...u, ...user })
							};
						}
						break;
				}
			}
			break;

		case ACTIONS.MOOD.LOAD_CURRENT_STATUS:
			if (!action.error)
			{
				const allMoods = action.payload.allMoods.reduce((rg, mood) =>
				{
					rg[mood.user] = mood;
					return rg;
				}, {});

				return {
					...state,
					moods: {
						...state.moods,
						...allMoods,
					},
				}
			}
			break;

		case MAGICUSERS.EVENT.NEWMOOD:
			if (!action.error)
			{
				const mood = action.payload.mood;
				return {
					...state,
					moods: {
						...state.moods,
						[mood.user]: mood
					},
				};
			}

			break;

		default:
			return state;
	}

	return state;
}