import React from 'react';

export default function(props)
{
	if(props.articlesCount <= 10)
		return null;

	const range=[];
	for(let i=0; i<Math.ceil(props.articlesCount/10); ++i)
		range.push(i);

	const setPage = page=>props.onSetPage(page);

	return(
	<nav>
		<ul className="pagination">
			{
				range.map(v=>{
					const isCurrent = v===props.currentPage;
					function onClick(ev)
					{
						ev.preventDefault();
						setPage(v);
					}
					return(
					<li
						className={isCurrent?'page-item active':'page-item'}
						onClick={onClick}
						key={v.toString()}>
							<button className="page-link">{v + 1}</button>
						</li>
					);
				})
			}
		</ul>
	</nav>
	);
}