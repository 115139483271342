import React from 'react';
import agent from "../../agent";
import { connect } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import { MoodPath, MoodName} from "../../Utils/moodglyph";

//import DropdownButton from 'react-bootstrap/DropdownButton';

import ACTIONS from "../../actiontypes";

const mapStateToProps = state => ({
	mood: state.mood,
	moodList: state.common.moodList,
	currentUser: state.common.currentUser,
});

const mapDispatchToProps = dispatch => ({
	onGetUserMood: payload =>
		dispatch({ type: ACTIONS.MOOD.LOAD, payload }),

});

export class MoodIndicator extends React.PureComponent
{
	render()
	{
		const { mood, moodList } = this.props;
		if (mood)
			return (
				<img
					alt={MoodName(mood, moodList)}
					title={MoodName(mood, moodList)}
					src={MoodPath(mood, moodList)} className={`${this.props.className} dskly-moodindicator`} 
					/>
			);

		return null;
	}
}

class MoodOption extends React.PureComponent
{
	render()
	{

		const mood = this.props.mood;
		const setmood = (ev) =>
		{
			this.props.onGetUserMood(agent.Moods.set(mood.id));
		}
		return (
			<Dropdown.Item
				value={mood.id}
				onClick={setmood}
			>
				<img alt="" src={mood.path} className="" />
			</Dropdown.Item>
		)
	}
}

class MoodSelect extends React.PureComponent
{

	componentDidMount()
	{
		//this.props.onGetUserList(agent.UserManager.list());
		this.props.onGetUserMood(agent.Moods.get(this.props.currentUser.id));
	}

	render()
	{
		if (!this.props.moodList)
			return null;

		console.debug("current mood", this.props.mood);


		return (
			<Dropdown id="moodselector_" >
				<Dropdown.Toggle variant="success" id="dropdown-moodselector_" className="mb-2">
					<MoodIndicator mood={this.props.mood} moodList={this.props.moodList} />
				</Dropdown.Toggle>
				<Dropdown.Menu className="text-center">
					{
						this.props.moodList
							.filter(e => e.id !== this.props.mood.mood)
							.map(mood => (
							<MoodOption
								key={mood.id}
								mood={mood}
								currentUser={this.props.currentUser}
								onGetUserMood={this.props.onGetUserMood}
								
							/>
						))
					}
				</Dropdown.Menu>
			</Dropdown >
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MoodSelect);

