import React from 'react';
import agent from "../../agent";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { v4 as uuidv4 } from 'uuid';

import Badge from 'react-bootstrap/Badge';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import GoalTitle from "../Goal/Title";
import { MoodIndicator } from "../User/Mood";
import { AvailabilityColor, AvailabilityLabel } from "../User/Available";

import Dskly from "../Dskly";
import UserImage from "./Image";
import { Addressee, CallHash, ChatUrl } from "../../Utils/Communicator";


/*
function GoalStatus(props)
{
	const { goal, currentgoal } = props;
	if (!goal || !currentgoal)
		return null;

	return (
		<React.Fragment>
			<GoalIndicator
				goal={currentgoal}
			/>
			&nbsp; {goal.description}
		</React.Fragment>
	)
}
*/
const mapStateToProps = state => ({
	activeConnections: state.connections,
});

class UserCard extends React.PureComponent
{
	componentDidMount()
	{
	}

	render()
	{
		const { user, currentUser, mood, moodList, onAction, actionSymbol, className, availabilityList, fIsOnline, activeConnections } = this.props;

		const availability = availabilityList[user.id] || {};
		const availabilityColor = AvailabilityColor(availability);

		const callhash = CallHash(user.id, currentUser.id);
		const address = Addressee.MakeTo(Addressee.Type.user, callhash);
		const fActiveCall = !!Object.values(activeConnections).find(connection => connection.address === address && connection.channel === "videocall");
		const fActiveChat = !!Object.values(activeConnections).find(connection => connection.address === address && connection.channel === "chat");


		return (
			<Dskly
				className={className}
				actionSymbol={actionSymbol}
				cdr={{
					from: currentUser.id,
					to: Addressee.MakeTo(Addressee.Type.user, user.id),
					address,
				}}
				fActiveCall={fActiveCall}
				fActiveChat={fActiveChat}
				onAction={() => onAction && onAction(user)}
				emailLink={`mailto:${user.email}`}
				chatLink={ChatUrl(address)}
				profileLink={`/@${user.email}`}
				profileImage={<UserImage
					image={user.image}
					name={user.username}
				/>
				}
				profileStyle={ { borderColor: fIsOnline?availabilityColor:"grey" }}

				content={
					<div className="font-weight-lighter text-truncate">
						<div>
							<MoodIndicator className="" mood={mood} moodList={moodList} />

							{fIsOnline &&
								<React.Fragment>
									<span style={{ color: availabilityColor }}>{AvailabilityLabel(availability)}</span>
								&nbsp;
									<Badge pill variant="success" className="">Online</Badge>

								</React.Fragment>
							}
						</div>
						<small>
							&nbsp;
							<GoalTitle
								className="text-center text-truncate"
								user={user}
								hastooltip={true}
							/>
						</small>
					</div>
				}

				headerContent={
					<React.Fragment >
						&nbsp;
						<span title={user.username}>
							{user.username}
						</span>
					</React.Fragment>

				}
			>

				{/* 
				<AvailabilityIndicator
					availability={availability}
				/>

 */}

			</Dskly>
		);
	}
}

export default connect(mapStateToProps, () => ({}))(UserCard);

