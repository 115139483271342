
function MoodEntry(mood, map)
{
	if (mood && map)
	{
		const m = map.find(e => e.id === mood.mood);
		if (m)
			return m;
	}

	if (map && map[0])
		return map[0];

}

export function MoodPath(mood, map)
{
	const m = MoodEntry(mood, map);

	return m? m.path: "Mood?";
}

export function MoodName(mood, map)
{
	const m = MoodEntry(mood, map);

	return m ? m.name : "";
}