import React from 'react';
import agent from "../../agent";
import { connect } from 'react-redux';
import InputGroup from 'react-bootstrap/InputGroup';

import { Typeahead, Highlighter } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';


import ACTIONS from "../../actiontypes";

import ToggleButton from "../ToggleButton";
import GoalToggle from "../Goal/Toggle";

const mapStateToProps = state => ({
	currentUser: state.common.currentUser,
	goalList: state.common.goalList,
	statuses: state.goalmatrix.statuses,
	teams: state.teams,
});

const mapDispatchToProps = dispatch => ({
	onGetUserGoal: payload =>
		dispatch({ type: ACTIONS.USERGOAL.LOAD, payload }),
	onCreateUserGoal: description =>
		dispatch({ type: ACTIONS.GOAL.LOAD_FORM, description }),
});



class GoalSelect extends React.PureComponent
{
	constructor()
	{
		super();

		this.state = {
			name: '',
			description: '',
			currentGoal: null,
		};


		this.filterBy = (option, state) =>
		{
			if (state.selected.length)
			{
				return true;
			}
			return option.description.toLowerCase().indexOf(state.text.toLowerCase()) > -1;
		}


		this.addgoal = (select) =>
		{

			if (select && select[0])
			{
				const { currentUser, onGoalSelect } = this.props;

				const goal = select[0];
				console.debug("addgoal", goal);

				if (this.props.goalList.some(g => (g.id === goal.id)))
				{
					if (onGoalSelect)
						onGoalSelect(goal);
					else
					{
						const rgStatuses = Object.values(this.props.statuses);
						const currentstatus = rgStatuses.find(gs => gs.user === currentUser.id && gs.goal === goal.id);

						console.debug("select goal", currentstatus, rgStatuses);
						agent.GoalMatrix.set(goal.id, currentstatus ? currentstatus.status : "init");
					}
				}
				else
				{
					//this.props.onCreateUserGoal(goal.description);
					if (goal.description && window.confirm(`Create "${goal.description}" ?`))
					{
						agent.Goals.set({ description: goal.description })
							.then(result =>
							{
								console.debug("confirmed add", result);
								agent.GoalMatrix.set(result.g.id, "init");
							});
					}
				}
			}
		}

		this.onMenuToggle = (isOpen) =>
		{
			this.setState({
				...this.state,
				fMenuOpen: isOpen
			});
		};

	}

	get latestStatus()
	{
		const { currentUser, statuses } = this.props;

		if (!(currentUser && statuses))
			return null;

		const rgStatuses = Object.values(statuses);

		return rgStatuses.filter(gs => gs.user === currentUser.id).reduce((acc, gs) =>
			acc ? (new Date(gs.updated_at) >= new Date(acc.updated_at) ? gs : acc) : gs
			, null
		);

	}


	render()
	{
		const ref = React.createRef();

		const { currentUser, goalList, statuses, allowNew, statusControl, selectedID, teams } = this.props;

		if (!(currentUser && goalList && statuses && teams && teams.details))
			return null;

		const aliveGoalList = goalList.filter(g => (
			(!g.deadline || (g.deadline && Date.now() < (new Date(g.deadline)).getTime())))
			&& (!g.private
				|| (g.private && (
					(g.team && teams.memberList[g.team]
						&& teams.memberList[g.team].some(m => m.user === currentUser.id))
					|| (!g.team && g.owner === currentUser.id)
				))
			)
		);

		const latestStatus = this.latestStatus;
		const selectedgoal = selectedID !== undefined ? aliveGoalList.find(g => g.id === selectedID) :
			(latestStatus && aliveGoalList.find(g => g.id === latestStatus.goal));

		console.debug("render select", selectedID, latestStatus, selectedgoal, statuses);
		const renderMenuItemChildren = (goal, { text }, index) => (
			<React.Fragment>
				<Highlighter search={text}>
					{goal.description}
				</Highlighter>{goal.deadline &&
					<small>
						{` (${(new Date(goal.deadline)).toLocaleDateString()})`}
					</small>
				}
			</React.Fragment>
		);

		return (
			<InputGroup>
				{ statusControl && selectedgoal && !this.state.fMenuOpen &&
					(<InputGroup.Prepend>
						<GoalToggle
							goal={selectedgoal}
							fToggleButton={true}
							status={latestStatus && latestStatus.status}
						/>
					</InputGroup.Prepend>
					)
				}
				<Typeahead
					ref={ref}

					{...(allowNew ? { allowNew: true } : {})}

					//{...(selectedgoal && !this.state.fMenuOpen ? { selected: [selectedgoal] } : {})}

					selected={selectedgoal && !this.state.fMenuOpen ? [selectedgoal] : []}
					renderMenuItemChildren={renderMenuItemChildren}
					newSelectionPrefix="New goal: "
					filterBy={this.filterBy}
					id="Goal-EntryForm-personal"
					options={aliveGoalList}
					labelKey="description"
					//onInputChange={(text, ev) => { console.debug(text, ev) }}
					onChange={(select) =>
					{
						this.addgoal(select);
						ref.current.blur();
					}
					}
					onMenuToggle={this.onMenuToggle}
					//onBlur={(ev)=>{console.debug(ev)}}
					placeholder="Choose a goal...">

					{({ isMenuShown, toggleMenu }) => (
						<ToggleButton isOpen={isMenuShown} onClick={e => toggleMenu()} />
					)}
				</Typeahead>
			</InputGroup>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(GoalSelect);
