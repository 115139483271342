import makeEnum from "../Utils/makeEnum";

const ACTION = makeEnum([
	"CONNECTED",
	"LISTENUSER",
]);

const EVENT = makeEnum([
	"NEWMOOD",
	"NEWGOAL",
	"NEWUSERGOAL",
	"NEWAVAILABILITY",
	"NEWPERSONALUSER",
	"PERSONALGROUP",
	"GROUP",
	"GROUPIE",
	"GOALMATRIX",
	"USERMANAGER",
	"COMMUNICATOR",
	"PRESENCE",
	"ARTICLE",
]);

export default {
	ACTION,
	EVENT,
};