import React from 'react';

const Spinner= () =>
	<div className='spinner fadein'>
		<i className="ion-clock" />
	</div>

const Images= props =>
	props.images.map((image, i) =>
		<div key={i} className='fadein'>
			<div
				onClick={() => props.removeImage(image.public_id)}
				className='delete'
			>
				<i className="ion-aperture" />
			</div>
			<img src={image.secure_url} alt='' />
		</div>
	)

const Buttons = props =>
	<div className='buttons fadein'>
		<div className='button'>
			<label htmlFor='single'>
				<i className="ion-image" />
			</label>
			<input type='file' id='single' onChange={props.onChange} />
		</div>
	</div>



export default class FileUpload extends React.Component
{

	state = {
		uploading: false,
		images: []
	}

	onChange = e =>
	{
		const files = Array.from(e.target.files)
		this.setState({ uploading: true })

		const formData = new FormData()

		files.forEach((file, i) =>
		{
			formData.append(i, file)
		})
	}

	removeImage = id =>
	{
		this.setState({
			images: this.state.images.filter(image => image.public_id !== id)
		})
	}

	render()
	{
		const { uploading, images } = this.state

		const content = () =>
		{
			switch (true)
			{
				case uploading:
					return <Spinner />
				case images.length > 0:
					return <Images images={images} removeImage={this.removeImage} />
				default:
					return <Buttons onChange={this.onChange} />
			}
		}

		return (
			<div>
				<div className='buttons'>
					{content()}
				</div>
			</div>
		)
	}
}