import ListErrors from "./ListErrors";
import React from 'react';
import agent from '../agent';
import { connect } from 'react-redux';


import ImageUploader from "react-images-upload";

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import InputGroup from 'react-bootstrap/InputGroup';

import MainHeader from "./Header";

import ACTIONS from "../actiontypes";


import FileUpload from "./FileUpload";

import ProfileImage from './Profile/Image';
import { Save as FileStoreSave } from "./Filestore";
import UserImage from "./User/Image";

class UserInfoForm extends React.Component
{
	cleanState()
	{
		return {
			image: '',
			username: '',
			bio: '',
			email: '',
			location: '',
			boss: '',
			title: '',
			department: '',
			phone: '',
			password: '',
		};
	}

	constructor()
	{
		super();

		this.state = this.cleanState();

		this.updateState = field => ev =>
		{
			const state = this.state;
			const newstate = Object.assign({}, state, { [field]: ev.target.value });
			this.setState(newstate);
		};

		this.submitForm = ev =>
		{
			ev.preventDefault();

			const user = Object.assign({}, this.state);
			if (!user.password)
				delete user.password;

			this.props.onSubmitForm(user);
		}

		this.onDrop = async (pictureFiles, pictureDataURLs) =>
		{
			const imgpath = await FileStoreSave(pictureFiles[0]);
			agent.Auth.save({ image: imgpath });
		}
	}

	componentDidMount()
	{
		this.updateUserInfo();
	}


	updateUserInfo()
	{
		const { userinfo } = this.props;

		if (userinfo)
		{
			this.setState({
				image: userinfo.image || '',
				username: userinfo.username,
				bio: userinfo.bio,
				email: userinfo.email,

				location: userinfo.location,
				boss: userinfo.boss,
				title: userinfo.title,
				department: userinfo.department,
				phone: userinfo.phone,

			});
		}
	}

	componentDidUpdate(prevProps)
	{
		if (this.props.userinfo !== prevProps.userinfo)
		{
			if (this.props.userinfo)
				this.updateUserInfo()
			else
				this.setState(this.cleanState());
		}
	}

	render()
	{
		const { image, username, email, password, title, location, boss, department, phone } = this.state;

		return (
			<Form onSubmit={this.submitForm}>
				{/* 				<Form.Group as={Row}>
					<Form.Label column="lg" lg={2}>
						Image
					</Form.Label>

					<Col lg={10}>
						<Form.Control
							size="lg"
							type="text"
							placeholder="URL of image"
							value={image}
							onChange={this.updateState('image')}
						></Form.Control>
					</Col>
				</Form.Group>
 */}
				<Form.Group as={Row}>
					<Form.Label column="lg" lg={2}>
						Image
					</Form.Label>

					<Col lg={10}>
						{image &&
							<ProfileImage
								className="ml-auto mr-auto w-50 pb-2"
							>
								<UserImage
									image
									name={username}
								/>
							</ProfileImage>

						}
						<ImageUploader
							withIcon={false}
							buttonText="Upload New"
							onChange={this.onDrop}
							buttonClassName="btn btn-primary"
							withLabel={false}
							singleImage
							imgExtension={[".jpg", ".gif", ".png", ".gif"]}
							maxFileSize={2 * 1024 * 1024}
						/>
					</Col>
				</Form.Group>

				<Form.Group as={Row}>
					<Form.Label column="lg" lg={2}>
						Name
					</Form.Label>

					<Col lg={10}>
						<Form.Control
							size="lg"
							type="text"
							placeholder="Username"
							value={username}
							onChange={this.updateState('username')}
						></Form.Control>
					</Col>
				</Form.Group>

				<Form.Group as={Row}>
					<Form.Label column="lg" lg={2}>
						Email
					</Form.Label>

					<Col lg={10}>
						<Form.Control
							size="lg"
							type="email"
							placeholder="Email"
							value={email}
							onChange={this.updateState('email')}
						></Form.Control>
					</Col>
				</Form.Group>


				<Form.Group as={Row}>
					<Form.Label column="lg" lg={2}>
						Title
					</Form.Label>

					<Col lg={10}>
						<Form.Control
							size="lg"
							type="text"
							//placeholder="Title"
							value={title}
							onChange={this.updateState('title')}
						></Form.Control>
					</Col>
				</Form.Group>

				<Form.Group as={Row}>
					<Form.Label column="lg" lg={2}>
						Department
					</Form.Label>

					<Col lg={10}>
						<Form.Control
							size="lg"
							type="text"
							//placeholder="Department"
							value={department}
							onChange={this.updateState('department')}
						></Form.Control>
					</Col>
				</Form.Group>

				<Form.Group as={Row}>
					<Form.Label column="lg" lg={2}>
						Phone
					</Form.Label>

					<Col lg={10}>
						<Form.Control
							size="lg"
							type="text"
							//placeholder="Phone #"
							value={phone}
							onChange={this.updateState('phone')}
						></Form.Control>
					</Col>
				</Form.Group>

				<Form.Group as={Row}>
					<Form.Label column="lg" lg={2}>
						Location
					</Form.Label>

					<Col lg={10}>
						<Form.Control
							size="lg"
							type="text"
							//placeholder="Location"
							value={location}
							onChange={this.updateState('location')}
						></Form.Control>
					</Col>
				</Form.Group>

				<Form.Group as={Row}>
					<Form.Label column="lg" lg={2}>
						Password
					</Form.Label>

					<Col lg={10}>
						<Form.Control
							size="lg"
							type="password"
							placeholder="Enter new password"
							value={password}
							onChange={this.updateState('password')}
						/>
					</Col>
				</Form.Group>

				<br />
				<Form.Group as={Row}>
					<Col lg={10}>
						<Button
							variant="primary"
							size="lg"
							className="pull-xs-right"
							type="submit"
							disabled={this.props.inProgress}
						>
							Update
						</Button>

					</Col>

				</Form.Group>
			</Form>
		);
	}
}

const mapStateToProps = state => ({
	...state.settings,
	currentUser: state.common.currentUser
});

const mapDispatchToProps = dispatch => ({
	onClickLogout: () =>
		dispatch({ type: ACTIONS.APP.LOGOUT })
	, onSubmitForm: user =>
		dispatch({ type: ACTIONS.SETTINGS.SAVED, payload: agent.Auth.save(user) })
	, onUnload: () =>
		dispatch({ type: ACTIONS.PAGE.SETTINGS_UNLOADED })
});

class Settings extends React.Component
{
	render()
	{
		return (
			<div className="settings-page">
				<MainHeader />
				<Container fluid className="page">
					<Col xs={12} md={{ span: 10, offset: 1 }}>
						<h1 className="text-xs-center">Your Settings</h1>
						<ListErrors errors={this.props.errors}></ListErrors>
						<UserInfoForm
							userinfo={this.props.currentUser}
							onSubmitForm={this.props.onSubmitForm} />
						<hr />
						{/* 							<button
								className="btn btn-outline-danger"
								onClick={this.props.onClickLogout}>
									Or logout.
								</button>
 */}					</Col>
				</Container>
			</div>
		);
	}

}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);