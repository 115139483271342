
class ExtendedSet extends Set
{
	IsSupersetOf(subset)
	{
		for (let elem of subset)
		{
			if (!this.has(elem))
			{
				return false
			}
		}
		return true
	}

	Union(setB)
	{
		let _union = new ExtendedSet(this)
		for (let elem of setB)
		{
			_union.add(elem)
		}
		return _union
	}

	Intersection(setB)
	{
		let _intersection = new ExtendedSet()
		for (let elem of setB)
		{
			if (this.has(elem))
			{
				_intersection.add(elem)
			}
		}
		return _intersection
	}

	SymmetricDifference(setB)
	{
		let _difference = new ExtendedSet(this)
		for (let elem of setB)
		{
			if (_difference.has(elem))
			{
				_difference.delete(elem)
			} else
			{
				_difference.add(elem)
			}
		}
		return _difference
	}

	Difference(setB)
	{
		let _difference = new ExtendedSet(this)
		for (let elem of setB)
		{
			_difference.delete(elem)
		}
		return _difference
	}
}

export default ExtendedSet;