
export default {
	set(jwt)
	{
		window.localStorage.setItem('jwt', jwt);
	},
	get()
	{
		return window.localStorage.getItem('jwt');
	},
	clear()
	{
		window.localStorage.removeItem('jwt');
	}
};