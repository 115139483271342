import App from "./components/App";
import { Provider } from 'react-redux';
import "./index.scss";

import ReactDOM from 'react-dom';
import React from 'react';

import {history, store} from "./store";


import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from 'connected-react-router';



ReactDOM.render((
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<Switch>
				<Route path="/" component={App}>
				</Route>
			</Switch>
		</ConnectedRouter>
	</Provider>

), document.getElementById('root'));

