import React from 'react';

export default function(props)
{
	const { image, name, emptyContent } = props;

	if (image && image.length)
	{
		return (
			<img
				src={image}
				alt={name}
			/>
		);
	}

	return (
		<div className="d-inline-block d-flex flex-column justify-content-center h-100">
			{emptyContent}
		</div>
	);
}
