import ACTIONS from "../actiontypes";

export default function (state = {}, action)
{
	switch (action.type)
	{
		case ACTIONS.PAGE.HOME_UNLOADED:
			return {};

			default:
			return state;

	}
}
