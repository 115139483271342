import ArticlePreview from "./ArticlePreview";
import ListPagination from "./ListPagination";
import React from 'react';

const ArticleList = props =>
{

	if (!props.articles)
		return (
			<div className="article-preview">Loading...</div>
		);

	if (props.articles.length === 0)
		return (
			<div className="article-preview">
				No articles found...
			</div>
		);

	return (
		<div>
			{
				props.articles.map(article =>
				{
					return (
						<ArticlePreview article={article} key={article.slug} />
					);
				})
			}

			<ListPagination
				articlesCount={props.articlesCount}
				currentPage={props.currentPage}
				onSetPage={props.onSetPage} />
		</div>
	);
}

export default ArticleList;