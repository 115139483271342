import ACTIONS from "../actiontypes";

export default function (state = {}, action)
{
	switch (action.type)
	{
		case ACTIONS.MOOD.LOAD:
			return {
				...state,
				...(action.payload.mood),
			};

		default:
			return state;
	}
}