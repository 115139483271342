
import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import agent from "../agent";
import { store } from '../store';
import { push } from 'connected-react-router';
import { Route, Switch, Redirect } from 'react-router-dom'


import Token from "../Utils/Token";
import Home from "./Home";

import ACTIONS from "../actiontypes";
import MAGICUSERS from "../MagicUsers/Konst";

const Article = lazy(() => import('./Article' /* webpackChunkName: "Article", webpackPrefetch: true  */))
const Editor = lazy(() => import('./Editor'/* webpackChunkName: "Editor", webpackPrefetch: true  */))
const Login = lazy(() => import('./Login'/* webpackChunkName: "Login", webpackPrefetch: true  */))
const Profile = lazy(() => import('./Profile'/* webpackChunkName: "Profile", webpackPrefetch: true  */))
const ProfileFavorites = lazy(() => import('./Profile/Favorites'/* webpackChunkName: "ProfileFavorites", webpackPrefetch: true  */))
const Settings = lazy(() => import('./Settings'/* webpackChunkName: "Settings", webpackPrefetch: true  */))
const TeamEditor = lazy(() => import('./Team/Editor'/* webpackChunkName: "TeamEditor", webpackPrefetch: true  */))
const TeamDetails = lazy(() => import('./Team'/* webpackChunkName: "TeamDetails", webpackPrefetch: true  */))
const Goal = lazy(() => import('./Goal/'/* webpackChunkName: "Goal", webpackPrefetch: true  */))
//const Call = lazy(() => import('./Call/'/* webpackChunkName: "Call", webpackPrefetch: true  */))
const Chat = lazy(() => import('./Chat/'/* webpackChunkName: "Chat", webpackPrefetch: true  */))
const OpenVidu = lazy(() => import('./OpenVidu/'/* webpackChunkName: "OpenVidu", webpackPrefetch: true  */))
const WaterCooler = lazy(() => import('./WaterCooler/'/* webpackChunkName: "WaterCooler", webpackPrefetch: true  */))
const MeetingEditor = lazy(() => import('./Meeting'/* webpackChunkName: "MeetingEditor", webpackPrefetch: true  */))




const mapStateToProps = state => ({
	appName: state.common.appName,
	currentUser: state.common.currentUser,
	redirectTo: state.common.redirectTo,
	appLoaded: state.common.appLoaded,
	moodList: state.common.moodList,
});

const mapDispatchToProps = dispatch => ({
	onLoad: (payload, token) =>
		dispatch({ type: ACTIONS.APP.LOAD, payload, token }),
	onGetUserList: payload =>
		dispatch({ type: ACTIONS.USERMAN.LOADED, payload }),
	onMoodLoad: (payload, token) =>
		dispatch({ type: ACTIONS.MOOD.LOAD_LIST, payload, token }),
	onGetGoalList: payload =>
		dispatch({ type: ACTIONS.GOAL.LOAD_LIST, payload }),
	onGetPublicTeamList: payload =>
		dispatch({ type: ACTIONS.TEAM.LOAD_PUBLIC, payload }),
	onGetSelfTeamList: payload =>
		dispatch({ type: ACTIONS.TEAM.LOAD_LIST, payload }),
	onGetTeamUsers: payload =>
		dispatch({ type: ACTIONS.TEAM.LOAD_USERS, payload }),
	onGetSelfTeamUsersList: payload =>
		dispatch({ type: ACTIONS.TEAM.LOAD_SELF_USERS, payload }),
	onGetPublicTeamUsers: payload =>
		dispatch({ type: ACTIONS.TEAM.LOAD_PUBLIC_USERS, payload }),

	onGetTeamDetails: payload =>
		dispatch({ type: ACTIONS.TEAM.LOAD_DETAILS, payload }),
	onGetUserMoods: payload =>
		dispatch({ type: ACTIONS.MOOD.LOAD_CURRENT_STATUS, payload }),
	onGetGoalMatrixIndex: payload =>
		dispatch({ type: ACTIONS.GOALMATRIX.LOAD_USER_STATUSES, payload }),
	onGetUserAvailability: payload =>
		dispatch({ type: ACTIONS.AVAILABILITY.LOAD_INDEX, payload }),
	onGetConnections: payload =>
		dispatch({ type: ACTIONS.CONNECTIONS.LIST, payload }),

	onRedirect: () =>
		dispatch({ type: ACTIONS.APP.REDIRECT }),
	onLogout: () =>
		dispatch({ type: ACTIONS.APP.LOGOUT }),
});


class App extends React.Component
{
	loadCurrentUser()
	{
		try
		{
			const token = Token.get();
			if (token)
			{
				agent.setToken(token)
			}


			agent.Auth.current()
				.then(res =>
				{
					if (!token)
						this.props.onLoad();
					else
					{
						this.props.onLoad(res, token);

						this.props.onGetUserList(
							agent.UserManager.list()
								.then((payload) =>
								{
									this.props.onGetUserMoods(agent.Moods.status());
									this.props.onGetUserAvailability(agent.Availability.index());
									this.props.onGetGoalMatrixIndex(agent.GoalMatrix.allStatus());
									return payload;
								})
						);

						this.props.onGetSelfTeamUsersList(agent.Team.listTeamsPrivateUsers());
						this.props.onGetPublicTeamUsers(agent.Team.listTeamsPublicUsers());

						this.props.onGetConnections(agent.Connection.list());
						this.props.onMoodLoad(agent.Moods.list());
						this.props.onGetGoalList(agent.Goals.list());
					}
				})
				.catch(err =>
				{
					console.error("bad auth", err);
					agent.setToken(null);
					Token.clear();

					this.props.onLoad();
				})
				;

		}
		catch (e)
		{
			console.warn(e);
		}
	}

	componentDidMount()
	{
		console.debug("App.mount");
		this.loadCurrentUser();
	}

	componentDidUpdate(prevProps)
	{
		function sameUser(a, b)
		{
			return (a === b) || (a && b && (a.id === b.id));
		}

		console.debug("App.update");
		if (!sameUser(this.props.currentUser, prevProps.currentUser))
		{
			this.loadCurrentUser();
		}

		if (this.props.redirectTo && this.props.redirectTo !== prevProps.redirectTo)
		{
			store.dispatch(push(this.props.redirectTo))
			this.props.onRedirect()
		}
	}

	render()
	{
		if (this.props.appLoaded)
		{
			return (
				<div className="dskly-viewport">
					<div className="dskly-content">
						<Suspense fallback={<p>Loading...</p>}>
							<Switch>
								<Route exact path='/'>
									{this.props.currentUser ? <Home /> : <Redirect to="/login" />}
								</Route>
								<Route path='/login' component={Login} />
								{/* 								<Route path='/register' component={Register} />
 */}								<Route path='/editor/:slug' component={Editor} />
								<Route path='/editor' component={Editor} />
								<Route path="/logout" render={() => { this.props.onLogout() }} />
								<Route path='/article/:id' component={Article} />
								<Route path='/settings' component={Settings} />
								<Route path='/team/:id' component={TeamDetails} />
								<Route path='/teamedit/:id' component={TeamEditor} />
								<Route path='/teamedit' component={TeamEditor} />
								<Route path='/goal/:id' component={Goal} />
								<Route path='/goal' component={Goal} />
								<Route path='/@:email/favorites' component={ProfileFavorites} />
								<Route path='/@:email' component={Profile} />
								<Route path='/call/:connectionid' component={OpenVidu} />
								<Route path='/chat/:chatid' component={Chat} />
								<Route path='/watercooler' component={WaterCooler} />
								<Route path='/meeting/:id' component={MeetingEditor} />
								<Route path='/meeting' component={MeetingEditor} />
							</Switch>
						</Suspense>

					</div>
				</div>
			)
		}

		return null;
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(App);