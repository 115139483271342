import React from "react";
import { connect } from 'react-redux';
import { Toast, Button, Container, Row, Col } from 'react-bootstrap';

import ACTIONS from "../../actiontypes";

const mapStateToProps = state => ({
	...state.home,
	appName: state.common.appName,
	notifications: state.notifications,
});

const mapDispatchToProps = dispatch => ({
	onHandled: notification =>
		dispatch({ type: ACTIONS.NOTIFICATION.MODIFY, notification }),

});

function NotificationStack(props)
{
	const { notifications, onHandled, className, autohide } = props;

	if (!notifications)
		return null;

	return (
		<Container fluid="sm" className={`dskly-notification d-flex flex-column justify-content-end align-items-end p-5 ${className}`}
		>
			{[...notifications]
				.sort((a, b) => b.timestamp - a.timestamp)
				.map((notification, i) =>
				{
					const { title, description, timestamp, actions, uuid, isHidden } = notification;

					const handleit = () =>
					{
						onHandled({
							uuid,
							isHidden: autohide,
						});
					}

					const fShow = !autohide || !isHidden;

					if (!fShow)
						return null;

					return (
						<Row key={timestamp}>
							<Col>
								<Toast
									key={uuid}
									show={fShow}
									style={{
										pointerEvents: "all",

									}}

									{...(autohide ? {
										onClose: handleit,
										autohide: true,
										delay: (process.env.REACT_APP_NOTIFICATION_LIFETIME_MILLISECONDS || 60 * 1000)
									} : {}
									)}
								>
									<Toast.Header
										closeButton={!!autohide}
									>
										<strong className="mr-auto">{title}</strong>
										<small>
											{(new Date(timestamp)).toLocaleTimeString()}
										</small>
									</Toast.Header>
									<Toast.Body>
										<Row>
											<Col sm={8}>
												{description}
											</Col>
											<Col sm={4} className="d-flex justify-content-end align-items-end">
												{!!actions && actions.map((action, i) =>
												{
													return (<div
														key={i}
														onClick={() =>
														{
															handleit();
														}}

													>
														{action}
													</div>);
												})}
											</Col>
										</Row>
									</Toast.Body>
								</Toast>
							</Col>
						</Row>
					);
				})}
		</Container >
	)
}


export default connect(mapStateToProps, mapDispatchToProps)(NotificationStack);