import ACTIONS from "../actiontypes";
import MAGICUSERS from "../MagicUsers/Konst";

export default function (state = {}, action)
{
	switch (action.type)
	{
		case ACTIONS.ARTICLE.FAVORITED:
		case ACTIONS.ARTICLE.UNFAVORITED:
			return {
				...state,
				articles: state.articles.map(article =>
				{
					if (article.slug === action.payload.article.slug)
					{
						return {
							...article,
							favorited: action.payload.article.favorited,
							favoritesCount: action.payload.article.favoritesCount
						};
					}
					return article;
				})
			};

		case ACTIONS.PAGE.HOME_LOADED:
			return {
				...state,
				articles: action.payload.articles,
				articlesCount: action.payload.articlesCount,
				tab: action.tab,
				currentPage: 0
			};

		case ACTIONS.PAGE.HOME_UNLOADED:
		case ACTIONS.PAGE.PROFILE_UNLOADED:
		case ACTIONS.PAGE.FAVORITES_UNLOADED:
			return {};

		case ACTIONS.APP.CHANGE_TAB:
				return {
					...state,
					articles: action.payload?action.payload.articles:undefined,
					articlesCount: action.payload?action.payload.articlesCount:undefined,
					tab: action.tab,
					currentPage: 0
				};

		case ACTIONS.PAGE.SET:
			return {
				...state,
				articles: action.payload.articles,
				articlesCount: action.payload.articlesCount,
				currentPage: action.page
			};

		case ACTIONS.PAGE.PROFILE_LOADED:
		case ACTIONS.PAGE.FAVORITES_LOADED:
			if (!action.error)
				return {
					...state,
					articles: action.payload[1].articles,
					articlesCount: action.payload[1].articlesCount,
					currentPage: 0
				};
			break;

		case MAGICUSERS.EVENT.ARTICLE:
			if (!action.error)
			{
				const { cmd, article } = action.payload;
				console.debug("MAGICUSERS.EVENT.ARTICLE", cmd, article)
				if (state.articles && state.articlesCount && ((!state.tab && !article.feed) || (state.tab && article.feed === state.tab)))
				{
					switch (cmd)
					{
						case "update":
							return {
								...state,
								articles: state.articles.map(a => (a.slug === article.slug) ? article : a),
							}
							break;
						case "create":
							return {
								...state,
								articles: ([article].concat(state.articles)).slice(0, 10),
								articlesCount: state.articlesCount + 1
							}
							break;

						case "remove":
							return {
								...state,
								articles: state.articles.filter(a => a.slug !== article.slug),
								articlesCount: state.articlesCount - 1
							}
							break;
					}
				}
			}
			break;

		default:
			return state;
	}
	return state;

}