import React from 'react';
import { Modal } from 'react-bootstrap';

export default function(props)
{
	return(
		<Modal
			show={props.show}
			onHide={props.onHide}
			size={props.size||"lg"}
			dialogClassName={props.dialogClassName}
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					{props.title}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{props.children}
			</Modal.Body>
		</Modal>		
	)
}