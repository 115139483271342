import React from 'react';
import agent from "../../agent";
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';

import GoalSelect from "./select";
import TeamSelect from "../Team/select";

import MainHeader from "../Header";

import ListErrors from "../ListErrors";

import ACTIONS from "../../actiontypes";

const mapStateToProps = state => ({
	...state.goal,
	currentUser: state.common.currentUser,
	goalList: state.common.goalList,
	currentgoals: state.common.currentgoals,
}
);

const mapDispatchToProps = dispatch => ({
	onSubmitForm: payload =>
		dispatch({ type: ACTIONS.GOAL.CREATE, payload }),
	onGetUserGoal: payload =>
		dispatch({ type: ACTIONS.USERGOAL.LOAD, payload }),
	onUnload: () =>
		dispatch({ type: ACTIONS.GOAL.UNLOAD_FORM }),
});

class GoalForm extends React.PureComponent
{
	cleanState()
	{
		return {
			description: '',
			details: "",
			dependent: null,
			team: null,
			deadline: '',
			private: false,

		};
	}

	updateStateField(field, value)
	{
		console.debug(field, "=", value);
		const state = this.state;
		const newstate = Object.assign({}, state, { [field]: value });
		this.setState(newstate);

	}

	constructor()
	{
		super();

		this.state = this.cleanState();

		this.updateState = (field, extractfield) => ev =>
		{
			const fieldvalue = (extractfield && extractfield in ev.target) ? ev.target[extractfield] : ev.target.value;
			this.updateStateField(field, fieldvalue);
		};

		this.submitForm = ev =>
		{
			ev.preventDefault();
			const goal = {
				...this.state,
				deadline: this.state.deadline && (new Date(this.state.deadline)).getTime()
			}
			console.debug("Submit new goal", goal);

			this.props.onSubmitForm(agent.Goals.set(goal)
				.then(result =>
				{
					if (!goal.team)
						agent.GoalMatrix.set(result.g.id, "init");
					return result;
				})
			);
		};

		this.isvalid = () => this.state.description.length > 2;

	}


	componentDidMount()
	{
		if (this.props.initGoal)
			this.setState({
				...this.state,
				...JSON.parse(JSON.stringify(this.props.initGoal))
			});
	}

	componentDidUpdate(prevProps)
	{
		//		console.debug(this.state, this.props.team, prevProps.team);
		if (this.props.initGoal !== prevProps.initGoal)
		{
			if (this.props.initGoal)
				this.setState({
					...this.state,
					...JSON.parse(JSON.stringify(this.props.initGoal))
				});
			else
				this.setState(this.cleanState());
		}


	}

	componentWillUnmount()
	{
		this.setState(this.cleanState());
		this.props.onUnload();
	}

	DoDelete(ev)
	{
		if (window.confirm("Are you sure you want to delete this goal permanently?"))
		{
			console.debug("Delete Goal", this.state);
			agent.Goals.set({
				id: this.state.id,
				defunct: true
			});
		}
	}

	render()
	{
		return (
			<Form onSubmit={this.submitForm}>
				<Form.Group>
					<Form.Row>
						<Form.Label column="lg" lg={2}>
							Name
					</Form.Label>
						<Col>
							<Form.Control
								as="textarea"
								rows="2"
								placeholder="Name"
								value={this.state.description}
								onChange={this.updateState('description')}
							/>
						</Col>
					</Form.Row>
					<br />
					<Form.Row>
						<Form.Label column="lg" lg={2}>
							Description
					</Form.Label>
						<Col>
							<Form.Control
								as="textarea"
								rows="4"
								placeholder="Description"
								value={this.state.details}
								onChange={this.updateState('details')}
							/>
						</Col>
					</Form.Row>
					<br />
					<Form.Row>
						<Form.Label column="lg" lg={2}>
							Visibile to all?
					</Form.Label>
						<Col>
							<Form.Check
								type="switch"
								id="GoalPrivacyToggle_1"
								checked={this.state.private}
								label={this.state.private ? "Private" : "Public"}
								onChange={this.updateState('private', "checked")}
							/>
						</Col>
					</Form.Row>
					<br />
					<Form.Row>
						<Form.Label column="lg" lg={2}>
							Deadline
					</Form.Label>
						<Col>
							<Form.Control
								type="date"
								id="GoalDeadline_1"
								value={this.state.deadline && (new Date(this.state.deadline)).toISOString().substr(0, 10)}
								onChange={this.updateState('deadline')}
							/>
						</Col>
					</Form.Row>
					<br />
					<Form.Row>
						<Form.Label column="lg" lg={2}>
							Dependent Goal
					</Form.Label>
						<Col>
							<GoalSelect
								selectedID={this.state.dependent}
								onGoalSelect={goal => this.updateStateField("dependent", goal.id)}
							/>
						</Col>
					</Form.Row>
					<br />

					{!this.props.isInUpdateMode &&
						<React.Fragment>
							<Form.Row>
								<Form.Label column="lg" lg={2}>
									Team
					</Form.Label>
								<Col>
									<TeamSelect
										onSelect={t => this.updateStateField("team", t.id)}
									/>
								</Col>
							</Form.Row>
							<br />
						</React.Fragment>

					}
				</Form.Group>
				<ButtonToolbar className="justify-content-between">
					<ButtonGroup>
						{this.isvalid() && (
							<Button disabled={!this.isvalid()} variant="primary" type="submit">
								Submit
							</Button>
						)
						}
					</ButtonGroup>
					<ButtonGroup>
						{this.props.isInUpdateMode && this.props.fDeletable &&
							<Button
								variant="danger"
								onClick={this.DoDelete.bind(this)}
							>
								Delete
							</Button>
						}
					</ButtonGroup>
				</ButtonToolbar>

			</Form>

		);
	}

}


class GoalEditor extends React.PureComponent
{
	render()
	{
		console.debug("GoalEditor render", this.props);
		const { currentUser, goalList, match: { params: { id } } } = this.props;

		if (!(currentUser && goalList))
			return null;

		const goal = id && goalList.find(g => g.id == id);
		const initGoal = goal || { description: this.props.editor || "" };
		const fDeletable = goal && goal.owner === currentUser.id;

		return (
			<div className="dskly-goal-page">
				<MainHeader />
				<Container fluid className="page">
					<Col xs={12} md={{ span: 10, offset: 1 }}>
						<h1 className="text-xs-center">{!!goal ? "Update" : "Create"} Goal</h1>
						<ListErrors errors={this.props.errors}></ListErrors>
						<GoalForm
							currentUser={currentUser}
							onSubmitForm={this.props.onSubmitForm}
							isInUpdateMode={!!goal}
							initGoal={initGoal}
							onUnload={this.props.onUnload}
							fDeletable={fDeletable}
						/>
					</Col>
				</Container>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(GoalEditor);

